import css from "./mapinfopane.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSIconType } from "../Icon/IconType";
import gsap from "gsap";
import { HHDSButton } from "../Button/Button";
import { HHDSImageAttrNames, ArgSpecs as ImageArgSpecs } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSMapInfoPane";
export const HHDSMapInfoPaneTagName: string = "hhds-map-info-pane";
const TAG_NAME: string = HHDSMapInfoPaneTagName;

export const HHDSMapInfoPaneAttrNames = {
  image: "image",
  header: "header",
  body: "body",
  beds: "beds",
  bath: "bath",
  garage: "garage",
  story: "story",
  sqft: "sqft",
  price: "price",
  cta: "cta",
  ctaTarget: "cta-target",
  show: "show",
  showDetail: "show-detail",
};

export enum HHDSMapInfoPaneEvent {
  show = "hhds-map-info-pane-show",
  hide = "hhds-map-info-pane-hide",
}

const Attrs = HHDSMapInfoPaneAttrNames;

export class HHDSMapInfoPane extends Component {
  showTween: gsap.core.Tween | null = null;
  hideTween: gsap.core.Tween | null = null;
  ignoreNextHideClick: boolean = false;

  constructor() {
    super();

    window.addEventListener("click", (_event: Event) => {
      if (this.ignoreNextHideClick) {
        this.ignoreNextHideClick = false;
        return;
      }
      this.hide();
    });
    this.shadow.addEventListener("click", (event) => event.stopPropagation());

    window.addEventListener("keydown", (event: KeyboardEvent) => {
      event.key === "Escape" && this.hide();
    });
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const imageUrl: string = this.vars.get<String>(Attrs.image);
    const header: string = this.vars.get<String>(Attrs.header);
    const body: string = this.vars.get<String>(Attrs.body);
    const beds: string = this.vars.get<String>(Attrs.beds);
    const bath: string = this.vars.get<String>(Attrs.bath);
    const garage: string = this.vars.get<String>(Attrs.garage);
    const story: string = this.vars.get<String>(Attrs.story);
    const sqft: string = this.vars.get<String>(Attrs.sqft);
    const price: string = this.vars.get<String>(Attrs.price);
    const ctaUrl: string = this.vars.get<String>(Attrs.cta);
    const ctaTarget: string = this.vars.get<String>(Attrs.ctaTarget);
    const show: boolean = this.vars.get<Boolean>(Attrs.show);

    if (!show) {
      this.shadow.innerHTML = "";
      return;
    }

    const infoTextSet = (title: string, content: string): string => {
      return `
          <hhds-richtext style="--richtext-eyebrow-color: var(--color-primary-darkgreen);"><p class="eyebrow eyebrow--01">${title}</p></hhds-richtext>
          <hhds-richtext style="--richtext-eyebrow-color: var(--color-neutral-grey-1000);"><p class="eyebrow eyebrow--01">${content}</p></hhds-richtext>
      `;
    };

    let infoRowHtml: string = ``;
    if (this.vars.get<Boolean>(Attrs.showDetail)) {
      infoRowHtml = `
      <div class="${TAG_NAME}__info-row">
        <div class="${TAG_NAME}__info-row-item b-right b-top">
          ${infoTextSet("BEDS", beds)}
        </div>
        <div class="${TAG_NAME}__info-row-item b-right b-top">
          ${infoTextSet("BATH", bath)}
        </div>
        <div class="${TAG_NAME}__info-row-item b-right b-top">
          ${infoTextSet("GARAGE", garage)}
        </div>
        <div class="${TAG_NAME}__info-row-item b-top">
          ${infoTextSet("STORY", story)}
        </div>
      </div>
      <div class="${TAG_NAME}__info-row">
        <div class="${TAG_NAME}__info-row-item b-top b-right b-bottom">
          ${infoTextSet("SQ. FT.", sqft)}
        </div>
        <div class="${TAG_NAME}__info-row-item b-top b-bottom">
          ${infoTextSet("PRICE", price)}
        </div>
      </div>
      `;
    } else {
      infoRowHtml = ``;
    }

    let closeButtonHtml: string = "";
    let imageHtml: string = "";
    if (imageUrl?.length) {
      closeButtonHtml = `<hhds-button class="close" type="square"><hhds-icon type="${HHDSIconType.Cross}"></hhds-icon></hhds-button>`;
      imageHtml = /* html */ `<hhds-image src="${imageUrl}" alt="${header}" ${this.markupResponsiveImages}></hhds-image>`;
    } else {
      closeButtonHtml = `<hhds-button class="close" type="square"><hhds-icon style="--icon-color: var(--color-neutral-grey-1000) !important;" type="${HHDSIconType.Cross}"></hhds-icon></hhds-button>`;
      imageHtml = ``;
    }

    let ctaHtml: string = ``;
    if (ctaUrl?.length) {
      ctaHtml = `<div class="${TAG_NAME}__cta-area">
        <hhds-button class="${TAG_NAME}__cta" href="${ctaUrl}" target="${ctaTarget}">Request More Info</hhds-button>
      </div>`;
    }

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
      <div class="${TAG_NAME}">
        <div class="container">
          <div class="grid">
            <div class="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4">
              <div class="${TAG_NAME}__pane">
                <div class="${TAG_NAME}__image">
                  ${closeButtonHtml}
                  ${imageHtml}
                </div>
                <div class="${TAG_NAME}__top-text">
                  <div class="${TAG_NAME}__header">
                    <hhds-richtext><h2 class="heading heading--02">${header}</h2></hhds-richtext>
                  </div>
                  <div class="${TAG_NAME}__body">
                    <hhds-richtext><p class="body body--01">${body}</p></hhds-richtext>
                  </div>
                </div>
                ${infoRowHtml}
                ${ctaHtml}
              </div>
            </div>
          </div>
        </div>
      </div>
		`;

    const closeButton = this.shadow.querySelector(".close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        this.hide();
      });
    }
  }

  show(): void {
    this.ignoreNextHideClick = false;
    this.setAttribute(HHDSMapInfoPaneAttrNames.show, "true");
    const pane = this.shadow.querySelector(`.${TAG_NAME}__pane`) as HTMLElement;
    gsap.set(pane, { opacity: 0, x: -10 });
    this.showTween = gsap.to(pane, {
      duration: 0.3,
      opacity: 1.0,
      x: 0,
    });
    this.emitEvent(HHDSMapInfoPaneEvent.show);
  }

  get markupResponsiveImages() {
    const attrSrcSet = this.vars.get<string>(HHDSImageAttrNames.srcSet);
    const attrSizes = this.vars.get<string>(HHDSImageAttrNames.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  get focusableElements(): HTMLElement[] {
    const cta = this.shadow.querySelector(
      `.${TAG_NAME}__cta-area hhds-button`
    ) as HHDSButton;
    const closeButton = this.shadow.querySelector(".close") as HHDSButton;
    let element: HTMLElement[] = [];
    cta.tabbableElement && element.push(cta.tabbableElement);
    closeButton.tabbableElement && element.push(closeButton.tabbableElement);
    return element;
  }

  hide(): void {
    if (this.hideTween?.isActive()) {
      return;
    }
    const pane = this.shadow.querySelector(`.${TAG_NAME}__pane`);
    gsap.set(pane, { x: 0 });
    this.hideTween = gsap.to(pane, {
      duration: 0.3,
      opacity: 0,
      x: -10,
      display: "none",
      onComplete: () => this.setAttribute(Attrs.show, "false"),
    });
    this.emitEvent(HHDSMapInfoPaneEvent.hide);
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.image]: {
    description: "Image URL",
    defaultValue: "",
    type: String,
  },
  [HHDSImageAttrNames.srcSet]: ImageArgSpecs[HHDSImageAttrNames.srcSet],
  [HHDSImageAttrNames.sizes]: ImageArgSpecs[HHDSImageAttrNames.sizes],

  [Attrs.header]: {
    description: "Header",
    defaultValue: "",
    type: String,
  },
  [Attrs.body]: {
    description: "Body text",
    defaultValue: "",
    type: String,
  },
  [Attrs.beds]: {
    description: "Bedrooms info text",
    defaultValue: "",
    type: String,
  },
  [Attrs.bath]: {
    description: "Bathrooms info text",
    defaultValue: "",
    type: String,
  },
  [Attrs.garage]: {
    description: "Garages info text",
    defaultValue: "",
    type: String,
  },
  [Attrs.story]: {
    description: "Number of stories",
    defaultValue: "",
    type: String,
  },
  [Attrs.sqft]: {
    description: "Square footage text",
    defaultValue: "",
    type: String,
  },
  [Attrs.price]: {
    description: "Price info text",
    defaultValue: "",
    type: String,
  },
  [Attrs.cta]: {
    description: "Call-to-action URL",
    defaultValue: "",
    type: String,
  },
  [Attrs.ctaTarget]: {
    description: "Call-to-action target",
    defaultValue: "_self",
    type: String,
  },
  [Attrs.show]: {
    description: "Show the map info pane",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.showDetail]: {
    description: "Show the property details",
    defaultValue: false,
    type: Boolean,
  },
};
