export enum HHDSIconType {
    None = 'none',
    Star = 'star',
    ArrowUp = 'arrowup',
    ArrowDown = 'arrowdown',
    ArrowLeft = 'arrowleft',
    ArrowRight = 'arrowright',
    ArrowUpRight = 'arrowupright',
    DirectionRight = 'directionright',
	DirectionLeft = 'directionleft',
    Warning = 'warning',
    Cross = 'cross',
    Plus = 'plus',
    CheckCircle = 'checkcircle',
    Play = 'play',
    User = 'user',
    DownloadSimple = 'downloadsimple',
    Phone = 'phone',
    X = 'x',
    LinkedIn = 'linkedin',
    Menu = 'menu',
}


// This lookup table is using the SVG icons from the Phosphor icon set
// https://phosphoricons.com/

export const HHDSIcons: { [key in HHDSIconType]: string } = {
    [HHDSIconType.Star]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.38,16.38,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65a8,8,0,0,0-8.38,0L69.09,215.94c-.15.09-.19.12-.38,0a.37.37,0,0,1-.17-.48l14.88-62.8a8,8,0,0,0-2.56-7.91l-48.7-42c-.12-.1-.23-.19-.13-.5s.18-.27.33-.29l63.92-5.16A8,8,0,0,0,103,91.86l24.62-59.61c.08-.17.11-.25.35-.25s.27.08.35.25L153,91.86a8,8,0,0,0,6.75,4.92l63.92,5.16c.15,0,.24,0,.33.29S224,102.63,223.84,102.73Z"></path></svg>`,
    [HHDSIconType.ArrowUp]: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M205.66,117.66a8,8,0,0,1-11.32,0L136,59.31V216a8,8,0,0,1-16,0V59.31L61.66,117.66a8,8,0,0,1-11.32-11.32l72-72a8,8,0,0,1,11.32,0l72,72A8,8,0,0,1,205.66,117.66Z"></path></svg>`,
    [HHDSIconType.ArrowDown]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M205.66,149.66l-72,72a8,8,0,0,1-11.32,0l-72-72a8,8,0,0,1,11.32-11.32L120,196.69V40a8,8,0,0,1,16,0V196.69l58.34-58.35a8,8,0,0,1,11.32,11.32Z"></path></svg>`,
    [HHDSIconType.ArrowLeft]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H59.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L59.31,120H216A8,8,0,0,1,224,128Z"></path></svg>`,
    [HHDSIconType.ArrowRight]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path></svg>`,
    [HHDSIconType.Warning]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path></svg>`,
    [HHDSIconType.Cross]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>`,
    [HHDSIconType.Plus]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>`,
    [HHDSIconType.CheckCircle]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>`,
    [HHDSIconType.ArrowUpRight]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M200,64V168a8,8,0,0,1-16,0V83.31L69.66,197.66a8,8,0,0,1-11.32-11.32L172.69,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z"></path></svg>`,
    [HHDSIconType.DirectionRight]: `<svg width="16" height="16" fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M 10.854 8.354 L 5.854 13.354 C 5.807 13.401 5.752 13.437 5.691 13.463 C 5.631 13.488 5.566 13.501 5.5 13.501 C 5.434 13.501 5.369 13.488 5.308 13.463 C 5.248 13.437 5.193 13.401 5.146 13.354 C 5.1 13.308 5.063 13.253 5.038 13.192 C 5.013 13.131 5 13.066 5 13 C 5 12.935 5.013 12.87 5.038 12.809 C 5.063 12.748 5.1 12.693 5.146 12.647 L 9.793 8 L 5.146 3.354 C 5.052 3.26 5 3.133 5 3 C 5 2.868 5.052 2.74 5.146 2.647 C 5.24 2.553 5.367 2.5 5.5 2.5 C 5.633 2.5 5.76 2.553 5.854 2.647 L 10.854 7.647 C 10.9 7.693 10.937 7.748 10.962 7.809 C 10.987 7.87 11 7.935 11 8 C 11 8.066 10.987 8.131 10.962 8.192 C 10.937 8.253 10.9 8.308 10.854 8.354 Z"/></svg>`,
    [HHDSIconType.DirectionLeft]: `<svg width="16" height="16" fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M 10.854 8.354 L 5.854 13.354 C 5.807 13.401 5.752 13.437 5.691 13.463 C 5.631 13.488 5.566 13.501 5.5 13.501 C 5.434 13.501 5.369 13.488 5.308 13.463 C 5.248 13.437 5.193 13.401 5.146 13.354 C 5.1 13.308 5.063 13.253 5.038 13.192 C 5.013 13.131 5 13.066 5 13 C 5 12.935 5.013 12.87 5.038 12.809 C 5.063 12.748 5.1 12.693 5.146 12.647 L 9.793 8 L 5.146 3.354 C 5.052 3.26 5 3.133 5 3 C 5 2.868 5.052 2.74 5.146 2.647 C 5.24 2.553 5.367 2.5 5.5 2.5 C 5.633 2.5 5.76 2.553 5.854 2.647 L 10.854 7.647 C 10.9 7.693 10.937 7.748 10.962 7.809 C 10.987 7.87 11 7.935 11 8 C 11 8.066 10.987 8.131 10.962 8.192 C 10.937 8.253 10.9 8.308 10.854 8.354 Z" style="transform-box: fill-box; transform-origin: 50% 50%;" transform="matrix(-1, 0, 0, -1, 0, 0)"></path></svg>`,
    [HHDSIconType.Play]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M232.4,114.49,88.32,26.35a16,16,0,0,0-16.2-.3A15.86,15.86,0,0,0,64,39.87V216.13A15.94,15.94,0,0,0,80,232a16.07,16.07,0,0,0,8.36-2.35L232.4,141.51a15.81,15.81,0,0,0,0-27ZM80,215.94V40l143.83,88Z"></path></svg>`,
    [HHDSIconType.User]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path></svg>`,
    [HHDSIconType.DownloadSimple]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,124.69V32a8,8,0,0,0-16,0v92.69L93.66,98.34a8,8,0,0,0-11.32,11.32Z"></path></svg>`,
    [HHDSIconType.Phone]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path></svg>`,
    [HHDSIconType.X]: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M214.75,211.71l-62.6-98.38,61.77-67.95a8,8,0,0,0-11.84-10.76L143.24,99.34,102.75,35.71A8,8,0,0,0,96,32H48a8,8,0,0,0-6.75,12.3l62.6,98.37-61.77,68a8,8,0,1,0,11.84,10.76l58.84-64.72,40.49,63.63A8,8,0,0,0,160,224h48a8,8,0,0,0,6.75-12.29ZM164.39,208,62.57,48h29L193.43,208Z"></path></svg>`,
    [HHDSIconType.LinkedIn]: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.42415 7.19151C9.41873 7.19987 9.41339 7.20817 9.40811 7.2164H9.42415V7.19151Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0493 0C1.4004 0 0.87439 0.513314 0.87439 1.14614V14.8539C0.87439 15.4869 1.4004 16 2.0493 16H15.5986C16.2475 16 16.7735 15.4869 16.7735 14.8539V1.14614C16.7735 0.513314 16.2475 0 15.5986 0H2.0493ZM5.6939 6.16861V13.3935H3.29248V6.16861H5.6939ZM5.85183 3.93405C5.85183 4.62738 5.33061 5.18217 4.49318 5.18217H4.47753C3.67164 5.18217 3.15053 4.62738 3.15053 3.93405C3.15053 3.22511 3.68762 2.68572 4.50907 2.68572C5.33061 2.68572 5.83623 3.22511 5.85183 3.93405ZM9.42409 13.3935H7.02269C7.02269 13.3935 7.05427 6.84654 7.02275 6.16861H9.42415V7.19151C9.74329 6.69921 10.3145 5.99907 11.5883 5.99907C13.1683 5.99907 14.353 7.03169 14.353 9.25085V13.3935H11.9518V9.52835C11.9518 8.55709 11.6042 7.89455 10.7352 7.89455C10.0718 7.89455 9.6767 8.34138 9.5031 8.77281C9.43969 8.92717 9.42409 9.14288 9.42409 9.35881V13.3935Z" fill="white"/>
    </svg>`,
    [HHDSIconType.Menu]: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Frame"><path id="Vector" d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8ZM2.5 4.5H13.5C13.6326 4.5 13.7598 4.44732 13.8536 4.35355C13.9473 4.25979 14 4.13261 14 4C14 3.86739 13.9473 3.74021 13.8536 3.64645C13.7598 3.55268 13.6326 3.5 13.5 3.5H2.5C2.36739 3.5 2.24021 3.55268 2.14645 3.64645C2.05268 3.74021 2 3.86739 2 4C2 4.13261 2.05268 4.25979 2.14645 4.35355C2.24021 4.44732 2.36739 4.5 2.5 4.5ZM13.5 11.5H2.5C2.36739 11.5 2.24021 11.5527 2.14645 11.6464C2.05268 11.7402 2 11.8674 2 12C2 12.1326 2.05268 12.2598 2.14645 12.3536C2.24021 12.4473 2.36739 12.5 2.5 12.5H13.5C13.6326 12.5 13.7598 12.4473 13.8536 12.3536C13.9473 12.2598 14 12.1326 14 12C14 11.8674 13.9473 11.7402 13.8536 11.6464C13.7598 11.5527 13.6326 11.5 13.5 11.5Z"/></g></svg>',
    [HHDSIconType.None]: ''
};