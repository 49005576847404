// IMPORTS
import {
  HHDSHelloWorld,
  HHDSHelloWorldTagName,
} from "./HelloWorld/HelloWorld.ts";
import { HHDSLink, HHDSLinkTagName } from "./Link/Link.ts";
import { HHDSEmoji, HHDSEmojiTagName } from "./Emoji/Emoji.ts";
import { HHDSIcon, HHDSIconTagName } from "./Icon/Icon.ts";
import { HHDSButton, HHDSButtonTagName } from "./Button/Button.ts";
import { HHDSBanner, HHDSBannerTagName } from "./Banner/Banner.ts";
import {
  HHDSButtonGroup,
  HHDSButtonGroupTagName,
} from "./ButtonGroup/ButtonGroup.ts";
import {
  HHDSCheckboxButton,
  HHDSCheckboxButtonTagName,
} from "./CheckboxButton/CheckboxButton.ts";
import { HHDSInput, HHDSInputTagName } from "./Input/Input.ts";
import { HHDSSelect, HHDSSelectTagName } from "./Select/Select.ts";
import { HHDSBadge, HHDSBadgeTagName } from "./Badge/Badge.ts";
import { HHDSTag, HHDSTagTagName } from "./Tag/Tag.ts";
import { HHDSTagGroup, HHDSTagGroupTagName } from "./TagGroup/TagGroup.ts";
import {
  HHDSBadgeGroup,
  HHDSBadgeGroupTagName,
} from "./BadgeGroup/BadgeGroup.ts";
import {
  HHDSVideoVimeo,
  HHDSVideoVimeoTagName,
} from "./VideoVimeo/VideoVimeo.ts";
import {
  HHDSVideoYoutube,
  HHDSVideoYoutubeTagName,
} from "./VideoYoutube/VideoYoutube.ts";
import {
  HHDSVideoDirect,
  HHDSVideoDirectTagName,
} from "./VideoDirect/VideoDirect.ts";
import { HHDSVideo, HHDSVideoTagName } from "./Video/Video.ts";
import { HHDSImage, HHDSImageTagName } from "./Image/Image.ts";
import { HHDSModal, HHDSModalTagName } from "./Modal/Modal.ts";
import { HHDSRichText, HHDSRichTextTagName } from "./RichText/RichText.ts";
import { HHDSCaption, HHDSCaptionTagName } from "./Caption/Caption.ts";
import { HHDSStat, HHDSStatTagName } from "./Stat/Stat.ts";
import { HHDSCard, HHDSCardTagName } from "./Card/Card.ts";
import {
  HHDSTextAndImage,
  HHDSTextAndImageTagName,
} from "./TextAndImage/TextAndImage.ts";
import {
  HHDSLayeredImage,
  HHDSLayeredImageTagName,
} from "./LayeredImage/LayeredImage.ts";
import { HHDSHero, HHDSHeroTagName } from "./Hero/Hero.ts";
import { HHDSText, HHDSTextTagName } from "./Text/Text.ts";
import {
  HHDSImageSlider,
  HHDSImageSliderTagName,
} from "./ImageSlider/ImageSlider.ts";
import { HHDSCarousel, HHDSCarouselTagName } from "./Carousel/Carousel.ts";
import {
  HHDSPeopleCarousel,
  HHDSPeopleCarouselTagName,
} from "./PeopleCarousel/PeopleCarousel.ts";
import {
  HHDSMastheadAndImage,
  HHDSMastheadAndImageTagName,
} from "./MastheadAndImage/MastheadAndImage.ts";
import { HHDSBaseTest, HHDSBaseTestTagName } from "./BaseTest/BaseTest.ts";
import { HHDSMasthead, HHDSMastheadTagName } from "./Masthead/Masthead.ts";
import { HHDSCTA, HHDSCTATagName } from "./CTA/CTA.ts";
import {
  HHDSTextAndStats,
  HHDSTextAndStatsTagName,
} from "./TextAndStats/TextAndStats.ts";
import { HHDSCardGrid, HHDSCardGridTagName } from "./CardGrid/CardGrid.ts";
import {
  HHDSPeopleGrid,
  HHDSPeopleGridTagName,
} from "./PeopleGrid/PeopleGrid.ts";
import { HHDSHomesGrid, HHDSHomesGridTagName } from "./HomesGrid/HomesGrid.ts";

import {
  HHDSImageFeature,
  HHDSImageFeatureTagName,
} from "./ImageFeature/ImageFeature.ts";
import { HHDSQuotation, HHDSQuotationTagName } from "./Quotation/Quotation.ts";
import { HHDSAccordion, HHDSAccordionTagName } from "./Accordion/Accordion.ts";
import {
  HHDSAccordionItem,
  HHDSAccordionItemTagName,
} from "./AccordionItem/AccordionItem.ts";
import {
  HHDSAccordionLayoutA,
  HHDSAccordionLayoutATagName,
} from "./AccordionLayoutA/AccordionLayoutA.ts";
import {
  HHDSMastheadAnimated,
  HHDSMastheadAnimatedTagName,
} from "./MastheadAnimated/MastheadAnimated.ts";
import {
  HHDSFeaturedInsight,
  HHDSFeaturedInsightTagName,
} from "./FeaturedInsight/FeaturedInsight.ts";
import { HHDSOfficeCta, HHDSOfficeCtaTagName } from "./OfficeCta/OfficeCta.ts";

import {
  HHDSResourcesTable,
  HHDSResourcesTableTagName,
} from "./ResourcesTable/ResourcesTable.ts";
import {
  HHDSResourcesTableItem,
  HHDSResourcesTableItemTagName,
} from "./ResourcesTableItem/ResourcesTableItem.ts";
import {
  HHDSLinkPreview,
  HHDSLinkPreviewTagName,
} from "./LinkPreview/LinkPreview.ts";
import {
  HHDSContactFormLayout,
  HHDSContactFormLayoutTagName,
} from "./ContactFormLayout/ContactFormLayout.ts";
import {
  HHDSContactList,
  HHDSContactListTagName,
} from "./ContactList/ContactList.ts";
import {
  HHDSContactListItem,
  HHDSContactListItemTagName,
} from "./ContactListItem/ContactListItem.ts";

import {
  HHDSVideoPreview,
  HHDSVideoPreviewTagName,
} from "./VideoPreview/VideoPreview.ts";
import {
  HHDSArticleContent,
  HHDSArticleContentTagName,
} from "./ArticleContent/ArticleContent.ts";
import {
  HHDSScrollDownButton,
  HHDSScrollDownButtonTagName,
} from "./ScrollDownButton/ScrollDownButton.ts";
import {
  HHDSMailchimpForm,
  HHDSMailchimpFormTagName,
} from "./MailchimpForm/MailchimpForm.ts";
import {
  HHDSCheckboxGroup,
  HHDSCheckboxGroupTagName,
} from "./CheckboxGroup/CheckboxGroup.ts";
import { HHDSSnazzyMap, HHDSSnazzyMapTagName } from "./SnazzyMap/SnazzyMap.ts";
import { HHDSImageLite, HHDSImageLiteTagName } from "./ImageLite/ImageLite.ts";
import { HHDSImageCard, HHDSImageCardTagName } from "./ImageCard/ImageCard.ts";
import {
  HHDSImageCards,
  HHDSImageCardsTagName,
} from "./ImageCards/ImageCards.ts";
import { HHDSHeroImage, HHDSHeroImageTagName } from "./HeroImage/HeroImage.ts";
import { HHDSHeroVideo, HHDSHeroVideoTagName } from "./HeroVideo/HeroVideo.ts";
import {
  HHDSCarouselLogo,
  HHDSCarouselLogoTagName,
} from "./CarouselLogo/CarouselLogo.ts";
import { HHDSDomino, HHDSDominoTagName } from "./Domino/Domino.ts";
import {
  HHDSDominoGrid,
  HHDSDominoGridTagName,
} from "./DominoGrid/DominoGrid.ts";
import {
  HHDSHeadingAndText,
  HHDSHeadingAndTextTagName,
} from "./HeadingAndText/HeadingAndText.ts";
import {
  HHDSSegmentedControl,
  HHDSSegmentedControlTagName,
} from "./SegmentedControl/SegmentedControl.ts";
import { HHDSHomesMap, HHDSHomesMapTagName } from "./HomesMap/HomesMap.ts";

import {
  HHDSImageAndText,
  HHDSImageAndTextTagName,
} from "./ImageAndText/ImageAndText.ts";
import {
  HHDSConnectorLine,
  HHDSConnectorLineTagName,
} from "./ConnectorLine/ConnectorLine.ts";
import { HHDSSubscribeForm, HHDSSubscribeFormTagName } from "./SubscribeForm/SubscribeForm.ts";
import { HHDSBuildersSection, HHDSBuildersSectionTagName } from "./BuildersSection/BuildersSection.ts";
import { HHDSBuilderCard, HHDSBuilderCardTagName } from "./BuilderCard/BuilderCard.ts";
import { HHDSMapInfoPane, HHDSMapInfoPaneTagName } from "./MapInfoPane/MapInfoPane.ts";
// LIST
const componentList: any = [];
componentList.push({ name: HHDSHelloWorldTagName, component: HHDSHelloWorld });
componentList.push({ name: HHDSLinkTagName, component: HHDSLink });
componentList.push({ name: HHDSEmojiTagName, component: HHDSEmoji });
componentList.push({ name: HHDSIconTagName, component: HHDSIcon });
componentList.push({ name: HHDSButtonTagName, component: HHDSButton });
componentList.push({ name: HHDSBannerTagName, component: HHDSBanner });
componentList.push({
  name: HHDSButtonGroupTagName,
  component: HHDSButtonGroup,
});
componentList.push({
  name: HHDSCheckboxButtonTagName,
  component: HHDSCheckboxButton,
});
componentList.push({ name: HHDSInputTagName, component: HHDSInput });
componentList.push({ name: HHDSSelectTagName, component: HHDSSelect });
componentList.push({ name: HHDSBadgeTagName, component: HHDSBadge });
componentList.push({ name: HHDSTagTagName, component: HHDSTag });
componentList.push({ name: HHDSTagGroupTagName, component: HHDSTagGroup });
componentList.push({ name: HHDSBadgeGroupTagName, component: HHDSBadgeGroup });
componentList.push({ name: HHDSVideoVimeoTagName, component: HHDSVideoVimeo });
componentList.push({
  name: HHDSVideoYoutubeTagName,
  component: HHDSVideoYoutube,
});
componentList.push({
  name: HHDSVideoDirectTagName,
  component: HHDSVideoDirect,
});
componentList.push({ name: HHDSVideoTagName, component: HHDSVideo });
componentList.push({ name: HHDSImageTagName, component: HHDSImage });
componentList.push({ name: HHDSModalTagName, component: HHDSModal });
componentList.push({ name: HHDSRichTextTagName, component: HHDSRichText });
componentList.push({ name: HHDSCaptionTagName, component: HHDSCaption });
componentList.push({ name: HHDSStatTagName, component: HHDSStat });
componentList.push({ name: HHDSCardTagName, component: HHDSCard });
componentList.push({
  name: HHDSTextAndImageTagName,
  component: HHDSTextAndImage,
});
componentList.push({
  name: HHDSLayeredImageTagName,
  component: HHDSLayeredImage,
});
componentList.push({ name: HHDSHeroTagName, component: HHDSHero });
componentList.push({ name: HHDSTextTagName, component: HHDSText });
componentList.push({
  name: HHDSImageSliderTagName,
  component: HHDSImageSlider,
});
componentList.push({ name: HHDSCarouselTagName, component: HHDSCarousel });
componentList.push({
  name: HHDSPeopleCarouselTagName,
  component: HHDSPeopleCarousel,
});
componentList.push({
  name: HHDSMastheadAndImageTagName,
  component: HHDSMastheadAndImage,
});
componentList.push({ name: HHDSBaseTestTagName, component: HHDSBaseTest });
componentList.push({ name: HHDSMastheadTagName, component: HHDSMasthead });
componentList.push({ name: HHDSCTATagName, component: HHDSCTA });

componentList.push({
  name: HHDSTextAndStatsTagName,
  component: HHDSTextAndStats,
});
componentList.push({ name: HHDSPeopleGridTagName, component: HHDSPeopleGrid });
componentList.push({ name: HHDSHomesGridTagName, component: HHDSHomesGrid });
componentList.push({ name: HHDSCardGridTagName, component: HHDSCardGrid });

componentList.push({
  name: HHDSImageFeatureTagName,
  component: HHDSImageFeature,
});
componentList.push({ name: HHDSQuotationTagName, component: HHDSQuotation });
componentList.push({
  name: HHDSAccordionLayoutATagName,
  component: HHDSAccordionLayoutA,
});
componentList.push({ name: HHDSAccordionTagName, component: HHDSAccordion });
componentList.push({
  name: HHDSAccordionItemTagName,
  component: HHDSAccordionItem,
});
componentList.push({
  name: HHDSMastheadAnimatedTagName,
  component: HHDSMastheadAnimated,
});
componentList.push({
  name: HHDSFeaturedInsightTagName,
  component: HHDSFeaturedInsight,
});
componentList.push({ name: HHDSOfficeCtaTagName, component: HHDSOfficeCta });

componentList.push({
  name: HHDSResourcesTableTagName,
  component: HHDSResourcesTable,
});
componentList.push({
  name: HHDSResourcesTableItemTagName,
  component: HHDSResourcesTableItem,
});

componentList.push({
  name: HHDSLinkPreviewTagName,
  component: HHDSLinkPreview,
});
componentList.push({
  name: HHDSContactFormLayoutTagName,
  component: HHDSContactFormLayout,
});

componentList.push({
  name: HHDSContactListTagName,
  component: HHDSContactList,
});
componentList.push({
  name: HHDSContactListItemTagName,
  component: HHDSContactListItem,
});

componentList.push({
  name: HHDSContactListTagName,
  component: HHDSContactList,
});
componentList.push({
  name: HHDSContactListItemTagName,
  component: HHDSContactListItem,
});

componentList.push({
  name: HHDSVideoPreviewTagName,
  component: HHDSVideoPreview,
});
componentList.push({
  name: HHDSArticleContentTagName,
  component: HHDSArticleContent,
});
componentList.push({
  name: HHDSScrollDownButtonTagName,
  component: HHDSScrollDownButton,
});
componentList.push({
  name: HHDSMailchimpFormTagName,
  component: HHDSMailchimpForm,
});
componentList.push({
  name: HHDSCheckboxGroupTagName,
  component: HHDSCheckboxGroup,
});
componentList.push({ name: HHDSSnazzyMapTagName, component: HHDSSnazzyMap });
componentList.push({ name: HHDSImageLiteTagName, component: HHDSImageLite });
componentList.push({ name: HHDSImageCardTagName, component: HHDSImageCard });
componentList.push({ name: HHDSImageCardsTagName, component: HHDSImageCards });
componentList.push({ name: HHDSHeroImageTagName, component: HHDSHeroImage });
componentList.push({ name: HHDSHeroVideoTagName, component: HHDSHeroVideo });
componentList.push({
  name: HHDSCarouselLogoTagName,
  component: HHDSCarouselLogo,
});
componentList.push({ name: HHDSDominoTagName, component: HHDSDomino });
componentList.push({ name: HHDSDominoGridTagName, component: HHDSDominoGrid });
componentList.push({
  name: HHDSHeadingAndTextTagName,
  component: HHDSHeadingAndText,
});
componentList.push({
  name: HHDSSegmentedControlTagName,
  component: HHDSSegmentedControl,
});
componentList.push({ name: HHDSHomesMapTagName, component: HHDSHomesMap });

componentList.push({
  name: HHDSImageAndTextTagName,
  component: HHDSImageAndText,
});
componentList.push({
  name: HHDSConnectorLineTagName,
  component: HHDSConnectorLine,
});
componentList.push({ name: HHDSSubscribeFormTagName, component: HHDSSubscribeForm });
componentList.push({ name: HHDSBuildersSectionTagName, component: HHDSBuildersSection });
componentList.push({ name: HHDSBuilderCardTagName, component: HHDSBuilderCard });
componentList.push({ name: HHDSMapInfoPaneTagName, component: HHDSMapInfoPane });
// EXPORTS
export { componentList };
