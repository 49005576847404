import css from "./herovideo.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSHeroVideo";
export const HHDSHeroVideoTagName: string = "hhds-herovideo";
const TAG_NAME: string = HHDSHeroVideoTagName;

export enum HHDSHeroVideoContentType {
  header = "header",
  full = "full",
}

export const HHDSHeroVideoAttrNames = {
  poster: "poster",
  src: "src",
  overlay: "overlay",
  contentType: "content-type",
};

const Attrs = HHDSHeroVideoAttrNames;

export class HHDSHeroVideo extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const overlayEnabled = this.vars.get<boolean>(Attrs.overlay);
    let contentType = this.vars.get<HHDSHeroVideoContentType>(Attrs.contentType);

    let contentClasses: string = "";
    let extraClasses: string = "";
    let textHtml: string = "";
    let ctaHtml: string = ``;
    if (contentType == HHDSHeroVideoContentType.header) {
      contentClasses += "hhds-herovideo__content--header-only";
      extraClasses += ` ${TAG_NAME}--header-only`;
      textHtml = `<div class="col-span-6 sm:col-span-8 md:col-span-12 lg:col-span-12"><slot></slot></div>`;
    } else {
      contentClasses += "hhds-herovideo__content--full";
      textHtml = `<div class="col-span-6 sm:col-span-8 md:col-span-9"><slot></slot></div>`;
      ctaHtml = `<div class="col-span-6 sm:col-span-8 md:col-span-3"><slot name="cta"></slot></div>`;
    }

    this.shadow.innerHTML = `
			<style>${css}</style>
			<style>${globalStyles}</style>
			<div class="${TAG_NAME} ${extraClasses}">
        <div class="hhds-herovideo__videocontainer" style="background-image: url('${this.vars.get("poster")}'); background-size: cover;"> 
          <video src="${this.vars.get("src")}" autoplay loop muted playsinline></video>
        </div>

        ${overlayEnabled ? `<div class="hhds-herovideo__overlay"></div>` : ""}

        <div class="hhds-herovideo__content ${contentClasses}">
          <div class="container">
            <div class="grid">
              ${textHtml}
              ${ctaHtml}
            </div>
          </div>
        </div>
      </div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.poster]: {
    description: "Poster frame URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.src]: {
    description: "Video source URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.overlay]: {
    description: "Whether to show overlay.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.contentType]: {
    description: "Content type.",
    defaultValue: "",
    type: String,
  },
};
