import css from "./textandstats.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { BlockBackgroundColourExpanded } from "../../Constants";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSTextAndStats";
export const HHDSTextAndStatsTagName: string = "hhds-text-and-stats";
const TAG_NAME: string = HHDSTextAndStatsTagName;

export const HHDSTextAndStatsAttrNames = {
  layout: "layout",
  background: "background",
};

export enum HHDSTextAndStatsLayout {
  header = "header",
  logo = "logo",
}

const Attrs = HHDSTextAndStatsAttrNames;

// ////////////////////////////////////////////////////////////////////

export class HHDSTextAndStats extends Component {
  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const attrLayout = this.vars.get<number>(Attrs.layout);
    const attrBackground = this.vars.get<number>(Attrs.background);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
    this.shadow.innerHTML = /* html */ `
    <style>${globalStyles}</style>
    <style>${css}</style>
    <div class="${TAG_NAME}" layout="${attrLayout}" background="${attrBackground}">
    <div class="container">
        <div class="grid">
            <div class="${TAG_NAME}__content col-span-6 sm:col-span-8 md:col-span-12 lg:col-start-3 lg:col-span-8"><slot></slot></div>
            <div class="${TAG_NAME}__stats col-span-6 sm:col-span-8 md:col-start-1 md:col-span-12 lg:col-start-2 lg:col-span-10"><slot name="stats"></slot></div>
            <div class="${TAG_NAME}__cta col-span-6 sm:col-span-8 md:col-start-1 md:col-span-12 lg:col-start-2 lg:col-span-10" name="cta"><slot name="cta"></slot></div>
        </div>
    </div>      
    `;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Hide empty slots

    const slotStats = this.shadow.querySelector(
      'slot[name="stats"]'
    ) as HTMLSlotElement;

    this.hideEmptySlot(slotStats);

    const slotCta = this.shadow.querySelector(
      'slot[name="cta"]'
    ) as HTMLSlotElement;

    this.hideEmptySlot(slotCta);

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);
  }

  hideEmptySlot(slot: HTMLSlotElement) {
    if (!slot) return;

    if (slot.assignedNodes().length === 0) {
      const { parentElement } = slot;
      if (parentElement) parentElement.style.display = "none";
    }
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.layout]: {
    description: "Text alignment",
    defaultValue: HHDSTextAndStatsLayout.header,
    type: HHDSTextAndStatsLayout,
  },
  [Attrs.background]: {
    description: "The background color",
    defaultValue: BlockBackgroundColourExpanded.white,
    type: BlockBackgroundColourExpanded,
  },
};
