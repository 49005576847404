import css from "./builderssection.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { AjaxFetcher } from "../AjaxFetcher";
import { HHDSSegmentedControl } from "../SegmentedControl/SegmentedControl";
import { HHDSHomesMap, HHDSHomesMapEvent } from "../HomesMap/HomesMap";
import {
  HHDSMapInfoPane,
  HHDSMapInfoPaneAttrNames,
  HHDSMapInfoPaneEvent,
  HHDSMapInfoPaneTagName,
} from "../MapInfoPane/MapInfoPane";
import { FocusHandler } from "../../utils/FocusHandler";
import { HHDSImageAttrNames } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSBuildersSection";
export const HHDSBuildersSectionTagName: string = "hhds-builders-section";
const TAG_NAME: string = HHDSBuildersSectionTagName;

export const HHDSBuildersSectionAttrNames = {
  body: "body",
  header: "header",
  badge: "badge",
  defaultView: "default-view",
  apiKey: "api-key",
};

enum HHDSBuildersSectionView {
  map = "map",
  list = "list",
}

interface CmsDevelopmentResult {
  development: CmsDevelopment;
  builder: string;
  builder_info: string;
  development_id: string;
  builder_img: string;
  builder_link_url: string;
  builder_link_target: string;
  builder_link_title: string;
  srcset: string;
  sizes: string;
}

interface CmsDevelopment {
  bath: string;
  beds: string;
  garage: string;
  id: string;
  price: string;
  sqft: string;
  story: string;
  body: string;
  image: any;
  property_details: boolean;
  srcset: string;
  sizes: string;
}

const Attrs = HHDSBuildersSectionAttrNames;

export class HHDSBuildersSection extends Component {
  private currentViewType: HHDSBuildersSectionView =
    HHDSBuildersSectionView.map;
  private infoPane: HHDSMapInfoPane | null = null;
  private focusHandler!: FocusHandler;

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    this.currentViewType = this.vars.get<HHDSBuildersSectionView>(
      Attrs.defaultView
    );
    this.render();
  }

  private fetchDevelopment(
    developmentId: string
  ): Promise<CmsDevelopmentResult | null> {
    const fetcher = new AjaxFetcher();
    //developmentId = "development-kbhome-01";
    const builderSlug = developmentId.split("-")[1];
    //console.log(`Fetching development with ID '${developmentId}' and builder slug '${builderSlug}'`);
    const params = { development_id: developmentId, builder_slug: builderSlug };
    return new Promise((resolve, reject) => {
      fetcher
        .fetch("fetch_builder_development", params)
        .then((result) => {
          DEBUG_VERBOSE &&
            console.log(CLASS_NAME, this.fetchDevelopment.name, { result });

          const development = result.data as CmsDevelopmentResult;
          if (!development) {
            if (this.infoPane) {
              this.infoPane.ignoreNextHideClick = false;
              this.infoPane.hide();
            }
            resolve(null);
          } else {
            resolve(development);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private render(): void {
    const headerText: string = this.vars.get<string>(Attrs.header);
    const bodyText: string = this.vars.get<string>(Attrs.body);
    const badgeText: string = this.vars.get<string>(Attrs.badge);
    const apiKey: string = this.vars.get<string>(Attrs.apiKey);
    const currentViewType: HHDSBuildersSectionView = this.currentViewType;

    let topHtml = /* html */ `
    <div class="${TAG_NAME}__top">
      <hhds-richtext><h2 class="eyebrow eyebrow--01">${badgeText}</h2></hhds-richtext>
      <hhds-richtext><h2 class="heading heading--02">${headerText}</h2></hhds-richtext>
      <hhds-richtext><p class="body body--03">${bodyText}</p></hhds-richtext>
      <hhds-segmented-control selected="${currentViewType}">
        <div value="map">Map View</div>
        <div value="list">List View</div>
      </hhds-segmented-control>
    </div>`;

    let bottomHtml: string = "";
    switch (currentViewType) {
      case HHDSBuildersSectionView.map:
        bottomHtml = /* html */ `
          <div class="${TAG_NAME}__main-view-container">
            <div class="${TAG_NAME}__map-view">
              <hhds-map-info-pane show="false"></hhds-map-info-pane>
              <hhds-homesmap key="${apiKey}" image="https://playground.chunk.co.uk/brian/temp/teravalis.svg"></hhds-homesmap>
            </div>
          </div>`;
        break;
      case HHDSBuildersSectionView.list:
        bottomHtml = /* html */ `
        <div class="container">
          <div class"grid">
            <div class="col-span-6 sm:col-span-8 md:col-span-12 lg:col-span-12">
              <div class="${TAG_NAME}__main-view-container">
                <div class="${TAG_NAME}__list-view">
                  <slot name="buildercards"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>`;
        break;
    }

    this.shadow.innerHTML = /* html */ `
        <style>${globalStyles}</style>
        <style>${css}</style>
        <div class="${TAG_NAME}">
            <div class="container">
                <div class="grid">
                    <div class="col-span-6 sm:col-span-8 md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4">
                        ${topHtml}
                    </div>
                </div>
            </div>
            ${bottomHtml}
        </div>
		`;

    const homesMap = this.shadow.querySelector("hhds-homesmap") as HHDSHomesMap;
    if (homesMap) {
      this.focusHandler = new FocusHandler(homesMap, true);

      homesMap.addEventListener(HHDSHomesMapEvent.pan, (_event: any) => {
        if (this.infoPane) this.infoPane.ignoreNextHideClick = true;
      });

      homesMap.addEventListener(
        HHDSHomesMapEvent.developmentClick,
        (event: any) => {
          if (this.infoPane) {
            this.infoPane.ignoreNextHideClick = true;
          }
          const id = event.detail?.id;
          const developmentEl = event.detail?.developmentEl;
          if (developmentEl) {
            this.focusHandler.setPreTrapFocusedElement(developmentEl);
          }
          this.fetchDevelopment(id).then(
            (result: CmsDevelopmentResult | null) => {
              if (result) {
                this.populateInfoPane(result);
              }
            }
          );
        }
      );
    }

    const segmentedControl = this.shadow.querySelector(
      "hhds-segmented-control"
    ) as HHDSSegmentedControl;
    segmentedControl.value = this.currentViewType;
    segmentedControl?.addEventListener("change", (event: Event) => {
      const control = event.target as HHDSSegmentedControl;
      this.currentViewType = control.value as HHDSBuildersSectionView;
      this.render();
    });

    this.infoPane = this.shadow.querySelector(
      HHDSMapInfoPaneTagName
    ) as HHDSMapInfoPane;
    this.infoPane.addEventListener(HHDSMapInfoPaneEvent.show, () => {
      this.focusHandler.updateFocusableElements(
        this.infoPane?.focusableElements
      );
      this.focusHandler.trap();
    });
    this.infoPane?.addEventListener(HHDSMapInfoPaneEvent.hide, () => {
      const refocusedElement = this.focusHandler.untrap();
      homesMap?.unselectDevelopments();
      if (refocusedElement)
        homesMap?.rehighlightDevelopmentIfRequired(refocusedElement.id);
    });
  }

  private populateInfoPane(cmsResult: CmsDevelopmentResult): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.populateInfoPane.name, { cmsResult });

    const development = cmsResult.development;

    let attributes: { [key: string]: string } = {
      [HHDSMapInfoPaneAttrNames.header]: cmsResult.builder,
      [HHDSMapInfoPaneAttrNames.bath]: development.bath,
      [HHDSMapInfoPaneAttrNames.beds]: development.beds,
      [HHDSMapInfoPaneAttrNames.garage]: development.garage,
      [HHDSMapInfoPaneAttrNames.price]: development.price,
      [HHDSMapInfoPaneAttrNames.sqft]: development.sqft,
      [HHDSMapInfoPaneAttrNames.story]: development.story,
    };
    if (development.body) {
      attributes[HHDSMapInfoPaneAttrNames.body] = development.body;
    } else if (cmsResult.builder_info) {
      attributes[HHDSMapInfoPaneAttrNames.body] = cmsResult.builder_info;
    }
    if (development.image) {
      attributes[HHDSMapInfoPaneAttrNames.image] = development.image.url;
    } else if (cmsResult.builder_img) {
      attributes[HHDSMapInfoPaneAttrNames.image] = cmsResult.builder_img;
    }

    if (cmsResult.srcset && cmsResult.sizes) {
      attributes[HHDSImageAttrNames.srcSet] = cmsResult.srcset;
      attributes[HHDSImageAttrNames.sizes] = cmsResult.sizes;
    }

    if (cmsResult.builder_link_url) {
      attributes[HHDSMapInfoPaneAttrNames.cta] = cmsResult.builder_link_url;
      attributes[HHDSMapInfoPaneAttrNames.ctaTarget] =
        cmsResult.builder_link_target;
    }
    attributes[HHDSMapInfoPaneAttrNames.showDetail] =
      development.property_details ? "true" : "false";
    Object.keys(attributes).forEach((key) => {
      this.infoPane?.setAttribute(key, attributes[key]);
    });
    this.infoPane?.show();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.apiKey]: {
    type: String,
    defaultValue: "",
    description: "API Key",
  },
  [Attrs.header]: {
    type: String,
    defaultValue: "",
    description: "Header text",
  },
  [Attrs.body]: {
    type: String,
    defaultValue: "",
    description: "Body text",
  },
  [Attrs.badge]: {
    type: String,
    defaultValue: "",
    description: "Badge text",
  },
  [Attrs.defaultView]: {
    type: HHDSBuildersSectionView,
    typeString: "HHDSBuildersSectionView",
    defaultValue: HHDSBuildersSectionView.map,
    description: "Default view",
  },
};
