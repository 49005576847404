import css from "./imagecards.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageCards";
export const HHDSImageCardsTagName: string = "hhds-imagecards";
const TAG_NAME: string = HHDSImageCardsTagName;

export const HHDSImageCardsAttrNames = {
  title: "title",
  eyebrow: "eyebrow",
};

const Attrs = HHDSImageCardsAttrNames;

const CAROUSEL_BREAKPOINT = 1024;
export class HHDSImageCards extends Component {
  private isGrid: boolean = true;

  constructor() {
    super();

    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this.renderAsGrid();

    this.addResizeListener();
    if (window.innerWidth < CAROUSEL_BREAKPOINT) {
      this.renderAsCarousel();
    }

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);
  }

  private renderAsGrid() {
    this.isGrid = true;

    let headerHtml = "";
    const eyebrow = this.vars.get("eyebrow");
    const title = this.vars.get("title");
    if (eyebrow?.length || title?.length) {
      headerHtml = `
      <div class="hhds-imagecards__header">
          <div class="container">
            <hhds-richtext>
              <p class="eyebrow eyebrow--01">${eyebrow}</p>
              <h2 class="heading heading--03">${title}</h2>
            </hhds-richtext>
          </div>
        </div>`;
    }

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
        ${headerHtml}
        <div class="hhds-imagecards__cards">
          <div class="container">
            <div class="grid">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
		`;

    const slotElement = this.shadow.querySelector("slot");
    const assignedElements = slotElement?.assignedElements({ flatten: true });

    assignedElements?.forEach((el) => {
      if (el.tagName.toLowerCase() === "hhds-imagecard") {
        el.classList.add("col-span-5", "sm:col-span-4");
      }
    });
  }

  private renderAsCarousel() {
    this.isGrid = false;

    const slotElement = this.shadow.querySelector("slot");
    const assignedElements = slotElement?.assignedElements({ flatten: true });

    if (assignedElements) {
      const content =
        assignedElements
          .filter((el) => el.tagName.toLowerCase() === "hhds-imagecard")
          .map((el) => el.outerHTML)
          .join("") || "";

      this.shadow.innerHTML = `

        <style>${globalStyles}</style>
        <style>${css}</style>
        <div class="${TAG_NAME}">
          <div class="hhds-imagecards__header">
            <div class="container">
              <hhds-richtext>
                <p class="eyebrow eyebrow--01">${this.vars.get("eyebrow")}</p>
                <h2 class="heading heading--03">${this.vars.get("title")}</h2>
              </hhds-richtext>
            </div>
          </div>
          <div class="hhds-imagecards__cards">
            <hhds-carousel nav="false" type="custom" slidesperview="2.1">
              ${content}
            </hhds-carousel>
          </div>
        </div>
      `;
    }
  }

  private addResizeListener() {
    window.addEventListener("resize", this.onResize);
  }

  private removeResizeListener() {
    window.removeEventListener("resize", this.onResize);
  }

  private onResize = () => {
    if (window.innerWidth < CAROUSEL_BREAKPOINT) {
      if (this.isGrid) {
        this.renderAsCarousel();
      }
    } else {
      if (!this.isGrid) {
        this.renderAsGrid();
      }
    }
  };

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
    this.removeResizeListener();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.eyebrow]: {
    description: "The eyebrow text",
    defaultValue: "",
    type: String,
  },
  [Attrs.title]: {
    description: "The title",
    defaultValue: "",
    type: String,
  },
};
