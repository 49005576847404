import css from './cta.scss?inline';
import globalStyles from '../../index.scss?inline';	
import { ArgSpecDictionary, TypedVars } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSCTA';
const TAG_NAME: string = 'hhds-cta';
export const HHDSCTATagName: string = "hhds-cta";

export enum CTAVariant {
	Gradient = 'gradient',	
	White = 'white',	
	Black = 'black'
}

export const HHDSCTAAttributeNames = {
	variant: "variant",		
}

const Attrs = HHDSCTAAttributeNames;


// ////////////////////////////////////////////////////////////////////



export class HHDSCTA extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;	

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	static get observedAttributes() {
		return Object.keys(ArgSpecs);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {

		this.parseAttributes();

		this.render();

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
	}

	render() {
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-cta hhds-cta__${this.vars.get<string>('variant')}">
				<div class="container">
					<div class="grid">
						<div class="col-span-6 sm:col-span-8 md:col-span-9">
							<hhds-richtext><slot name="text"></slot></hhd-richtext>
						</div>
						<div class="hhds-cta__button col-span-6 sm:col-span-3 ">
							<slot class="align-items: flex-end;" name="end"></slot>
						</div>
					</div>
				</div>
			</div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);
		this.parseAttributes();
		this.render();
	}

}

export const ArgSpecs: ArgSpecDictionary = {
	[Attrs.variant]: {
		description: "Variant type",
		defaultValue: CTAVariant.Gradient,
		type: CTAVariant,
	}	
};

