import css from "./dominogrid.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSDominoGrid";
export const HHDSDominoGridTagName: string = "hhds-dominogrid";
const TAG_NAME: string = HHDSDominoGridTagName;

export const HHDSDominoGridAttrNames = {
  title: "title",
  mode: "mode",
};

const Attrs = HHDSDominoGridAttrNames;

export class HHDSDominoGrid extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const mode = this.vars.get(Attrs.mode);
    this.shadow.innerHTML = /* html */ `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME} ${TAG_NAME}--${mode}">
        <div class="hhds-dominogrid__container"> 
          <div class="hhds-dominogrid__content">
            <div class="container" style="width: 100%;">
              <hhds-richtext>
                <p class="eyebrow eyebrow--01">${this.vars.get(Attrs.title)}</p>
              </hhds-richtext>
            </div>
            <slot></slot>
          </div>
        </div>
      </div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.title]: {
    description: "The title.",
    defaultValue: "",
    type: String,
  },
  [Attrs.mode]: {
    description: "The colour mode.",
    defaultValue: "light",
    type: String,
  },
};
