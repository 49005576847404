import css from "./heroimage.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

import { HHDSImageAttrNames, ArgSpecs as ImageArgSpecs } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSHeroImage";
export const HHDSHeroImageTagName: string = "hhds-heroimage";
const TAG_NAME: string = HHDSHeroImageTagName;

export const HHDSHeroImageAttrNames = {
  src: "src",
  srcMobile: "src-mobile",
  headerSrc: "header-src",
  title: "title",
  overlay: "overlay",
  srcSet: "src-set",
  sizes: "sizes",
  srcSetMobile: "src-set-mobile",
  sizesMobile: "sizes-mobile",
};

const Attrs = HHDSHeroImageAttrNames;

export class HHDSHeroImage extends Component {
  constructor() {
    super();

    this.observeBreakpointChanges((breakpoint: string) => this.updateForBreakpoint(breakpoint));
  }

  private updateForBreakpoint(_breakpoint: string | null = null) {
    this.reinit();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const overlayEnabled = this.vars.get<boolean>(Attrs.overlay);

    const srcMobile = this.vars.get(Attrs.srcMobile);
    const useMobileImg = this.currentBreakpoint == "xs" && srcMobile?.length > 0;
    const src = useMobileImg ? srcMobile : this.vars.get(Attrs.src);

    this.shadow.innerHTML = /* html */ `
			<style>${css}</style>
			<style>${globalStyles}</style>
			<div class="${TAG_NAME} container--fullwidth">
                <hhds-image
                src="${src}"
                ${this.getMarkupResponsiveImages(useMobileImg)}
                ></hhds-image>  
                ${overlayEnabled ? `<div class="${TAG_NAME}__overlay"></div>` : ""}
                <div class="${TAG_NAME}__content">
                    ${this.markupHeader}
                </div>
            </div>
		`;
  }

  private getMarkupResponsiveImages(mobile: boolean) {
    const attrSrcSet = this.vars.get<string>(mobile ? Attrs.srcSetMobile : Attrs.srcSet);
    const attrSizes = this.vars.get<string>(mobile ? Attrs.sizesMobile : Attrs.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  get markupHeader() {
    const title = this.vars.get(Attrs.title);
    const headerSrc = this.vars.get(Attrs.headerSrc);

    if (headerSrc) {
      return /* html */ `<img class="${TAG_NAME}__content-image" src="${headerSrc}" >`;
    } else {
      return /* html */ `
      <div class="grid">
        <div class="col-span-6 sm:col-span-8 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
          <hhds-richtext><h1 class="heading heading--05">${title}</h1></hhds-richtext>
        </div>
      </div>
      `;
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "Image source URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.srcMobile]: {
    description: "Optional image source URL for mobile.",
    defaultValue: "",
    type: String,
  },
  [Attrs.headerSrc]: {
    description: "Header Image URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.title]: {
    description: "Title.",
    defaultValue: "",
    type: String,
  },
  [Attrs.overlay]: {
    description: "Whether to show overlay.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.srcSet]: ImageArgSpecs[HHDSImageAttrNames.srcSet],
  [Attrs.sizes]: ImageArgSpecs[HHDSImageAttrNames.sizes],
  [Attrs.srcSetMobile]: {
    description:
      "Defines the set of images we will allow the browser to choose between on the XS breakpoint.",
    defaultValue: "",
    type: String,
  },
  [Attrs.sizesMobile]: {
    description:
      "Defines a set of media conditions (e.g. screen widths) and indicates what image size would be best to choose, when certain media conditions are true on the XS breakpoint.",
    defaultValue: "",
    type: String,
  },
};
