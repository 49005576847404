import css from "./carousellogo.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import swiperStyles from "../../../node_modules/swiper/swiper-bundle.min.css?inline";
import { Autoplay } from "swiper/modules";
import Swiper from "swiper";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSCarouselLogo";
const TAG_NAME: string = "hhds-carousellogo";
export const HHDSCarouselLogoTagName: string = "hhds-carousellogo";

// ////////////////////////////////////////////////////////////////////

export class HHDSCarouselLogo extends HTMLElement {
  private vars: TypedVars = new TypedVars(this);
  private shadow: ShadowRoot;

  private swiperContainer: HTMLElement | null = null;
  private swiperInstance: Swiper | null = null;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.shadow = this.attachShadow({ mode: "open" });

    if (!this.shadow) throw new Error("Unable to attach shadowRoot");

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  }

  static get observedAttributes() {
    return Object.keys(ArgSpecs);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.parseAttributes();

    this.render();

    requestAnimationFrame(() => {
      this.initializeSwiper();
    });

    DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
  }

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
    DEBUG_VERBOSE && console.log(`this.vars`, this.vars);
  }

  render() {
    this.shadow.innerHTML = /* html */ `
		<style>${swiperStyles}</style>
		<style>${globalStyles}</style>
		<style>${css}</style>
		<div class="hhds-carousellogo">
			<div class="wrapper hhds-carousellogo-slider">
				<div class="swiper ${HHDSCarouselLogoTagName}">
					<div class="swiper-wrapper">
						<slot class="slides"></slot>
					</div>
				</div>
			</div>
		</div>

		`;

    this.swiperContainer = this.shadowRoot?.querySelector(".swiper") as HTMLElement;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Copy all elements in the slot into .swiper-wrapper

    const slidesEl = this.shadowRoot?.querySelector(".slides");

    if (slidesEl && slidesEl instanceof HTMLSlotElement) {
      const swiperWrapperSelector = ".swiper-wrapper";
      const swiperWrapper = this.shadowRoot?.querySelector(swiperWrapperSelector);

      if (!swiperWrapper) throw new Error(`Unable to derive selector: ${swiperWrapperSelector}`);

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      const elements = slidesEl.assignedElements() as HTMLElement[];

      DEBUG_VERBOSE && console.log(`elements`, elements);

      elements.forEach((element) => {
        element.classList.add("swiper-slide");
        element.addEventListener("focusin", (event: FocusEvent) => {
          const relatedTarget = event.relatedTarget as Node;

          if (!element.contains(relatedTarget)) {
            this.handleContentFocus(element);
          }
        });
        swiperWrapper.appendChild(element);
      });
    }
  }

  handleContentFocus = (element: HTMLElement) => {
    if (!this.swiperInstance) {
      throw new Error("No swiper instance!");
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const { slides, activeIndex } = this.swiperInstance;

    // Get the index of the slide which has focus
    const focusIndex = slides.indexOf(element);

    // If the slide with focus is not the active slide then we move to the slide with focus
    if (activeIndex !== focusIndex) {
      this.swiperInstance.slideTo(focusIndex);
    }

    DEBUG_VERBOSE &&
      console.log(
        CLASS_NAME,
        "handleContentFocus",
        element,
        `focusIndex`,
        focusIndex,
        activeIndex === focusIndex
      );
  };

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.swiperDestroy();
  }

  swiperDestroy() {
    if (this.swiperInstance) {
      this.swiperInstance.destroy(true, true);
    }
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);

    /**
     * Commented out as causing issue with some default attributes triggering
     * attributeChangedCallback and the followup code not working as expected.
     * The swiper instance was being removed and not re-instantiated correctly.
     * Also unsure as to why some attributes were triggering attributeChangedCallback
     */

    // this.swiperDestroy();
    // 	this.parseAttributes();
    // 	this.render();
    // 	this.initializeSwiper();
  }

  initializeSwiper() {
    if (!this.swiperContainer) {
      throw new Error("No swiperContainer");
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.swiperInstance = new Swiper(this.swiperContainer, {
      modules: [Autoplay],
      slideFullyVisibleClass: "swiper-slide-visible",
      allowTouchMove: false,
      spaceBetween: 24,
      slidesPerView: "auto",
      loop: true,
      speed: 5500,
      freeMode: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          spaceBetween: 20,
        },
        1024: {
          spaceBetween: 24,
        },
        1366: {
          spaceBetween: 32,
        },
      },
      on: {
        init: () => {
          requestAnimationFrame(() => {
            this.handleVisibleSlides();
          });
        },
        slideChange: (swiper: Swiper) => {
          this.handleVisibleSlides();
          DEBUG_VERBOSE && console.log("slideChange", swiper);
        },
        resize: () => {
          this.handleVisibleSlides();
        },
      },
    });

    this.swiperInstance.init();
  }

  handleVisibleSlides() {
    if (!this.swiperInstance) {
      return;
    }

    const { realIndex, slides } = this.swiperInstance;

    const slidesPerView = this.swiperInstance.params.slidesPerView as number;

    slides.forEach((slide, index) => {
      const min = realIndex;
      const max = realIndex + slidesPerView;
      let state = index >= min && index < max;

      if (window.innerWidth < 640) {
        state = index == realIndex;
      }

      slide.classList.toggle("swiper-slide-visible", state);
    });
  }
}

export const enum ArgSpecsKey {
  SlidesPerView = "slidesPerView",
  TransitionSpeed = "transitionSpeed",
  Type = "type",
  Title = "title",
  Nav = "nav",
  Link = "link",
}

export const ArgSpecs: ArgSpecDictionary = {};
