export class GradientAnimator {
  private transitionSpeed: number = 0.07;
  private animationFrame: number | null = null;
  private animationDirection: GradientAnimator.Direction = GradientAnimator.Direction.forward;
  private initialColors: string[];
  private targetColors: string[];
  private progressState: number[];
  private gradientStops: NodeListOf<SVGStopElement>;

  constructor(
    parentElement: HTMLElement | ShadowRoot | SVGLinearGradientElement,
    stopColors: GradientAnimator.StopColors
  ) {
    this.gradientStops = parentElement.querySelectorAll("stop");
    this.initialColors = stopColors.start;
    this.targetColors = stopColors.end;
    this.progressState = Array.from({ length: this.gradientStops.length }, () => 0);
    this.resetToInitialColors();
  }

  play(direction: GradientAnimator.Direction): void {
    this.animationDirection = direction;
    if (this.animationFrame) cancelAnimationFrame(this.animationFrame);
    this.animationFrame = null;
    this.animateGradient();
  }

  setGradient(direction: GradientAnimator.Direction): void {
    this.animationDirection = direction;
    if (this.animationFrame) cancelAnimationFrame(this.animationFrame);
    this.animationFrame = null;

    for (let i = 0; i < this.gradientStops.length; i++) {
      this.progressState[i] = this.animationDirection == GradientAnimator.Direction.forward ? 1 : 0;
      this.gradientStops[i].style.stopColor = this.targetColors[i];
    }
  }

  private interpolateColor(start: string, end: string, progress: number): string {
    const startRGB = start.match(/\w\w/g)?.map((hex) => parseInt(hex, 16));
    const endRGB = end.match(/\w\w/g)?.map((hex) => parseInt(hex, 16));
    if (!startRGB || !endRGB) return start;
    const resultRGB = startRGB.map((startChannel, i) => {
      return Math.round(startChannel + (endRGB[i] - startChannel) * progress);
    });
    return `rgb(${resultRGB.join(",")})`;
  }

  private resetToInitialColors(): void {
    for (let i = 0; i < this.gradientStops.length; i++) {
      this.progressState[i] = 0;
      this.gradientStops[i].style.stopColor = this.initialColors[i];
    }
  }

  private animateGradient(): void {
    let shouldContinue = false;
    const targetProgress = this.animationDirection == GradientAnimator.Direction.forward ? 1 : 0;

    for (let i = 0; i < this.gradientStops.length; i++) {
      if (this.progressState[i] !== targetProgress) {
        shouldContinue = true;
        if (this.progressState[i] < targetProgress) {
          this.progressState[i] = Math.min(this.progressState[i] + this.transitionSpeed, 1);
        } else {
          this.progressState[i] = Math.max(this.progressState[i] - this.transitionSpeed, 0);
        }
        const newColor = this.interpolateColor(
          this.initialColors[i],
          this.targetColors[i],
          this.progressState[i]
        );
        this.gradientStops[i].style.stopColor = newColor;
      }
    }
    if (shouldContinue) {
      this.animationFrame = requestAnimationFrame(this.animateGradient.bind(this));
    }
  }
}

export namespace GradientAnimator {
  export interface StopColors {
    start: string[];
    end: string[];
  }
  export enum Direction {
    forward = "forward",
    back = "back",
  }
}
