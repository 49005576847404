interface WPAjaxObject {
  ajax_url: string;
  nonce: string;
}
declare var ajax_obj: WPAjaxObject;

export class AjaxFetcher {
  constructor() {}

  async fetch(action: string, params: Record<string, string> = {}): Promise<any> {
    if (typeof ajax_obj === "undefined") {
      console.error("Can't use AjaxFetcher as there is no ajax_obj defined");
      return [];
    }

    let body = new URLSearchParams({
      action: action,
      nonce: ajax_obj.nonce,
      ...params,
    });
    const method: string = "POST";
    const headers: { [key: string]: string } = { "Content-Type": "application/x-www-form-urlencoded" };
    const response = await fetch(ajax_obj?.ajax_url, {
      method: method,
      headers: headers,
      body: body,
    });
    const result = await response.json();
    return result;
  }
}
