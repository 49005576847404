import css from "./card.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSCardGridType } from "../CardGrid/CardGrid.enums";
import { HHDSTagGroup } from "../TagGroup/TagGroup";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSCard";
const TAG_NAME: string = "hhds-card";
export const HHDSCardTagName: string = "hhds-card";

// ////////////////////////////////////////////////////////////////////

export const HHDSCardGridAttrNames = {
  date: "date",
  type: "type",
  morePadding: "more-padding",
  title: "title",
  description: "description",
  cta: "cta",
  url: "url",
  target: "target",
  categoryData: "category-data",
};

const Attrs = HHDSCardGridAttrNames;

export class HHDSCard extends HTMLElement {
  private vars: TypedVars = new TypedVars(this);
  private shadow: ShadowRoot;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.shadow = this.attachShadow({ mode: "open" });
    if (!this.shadow) {
      return;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.parseAttributes();

    this.render();

    DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
  }

  get tagGroup(): HHDSTagGroup | null {
    const slot = this.shadow.querySelector('slot[name="tags"]') as HTMLSlotElement;
    const tagGroup = slot.assignedElements()[0] as HHDSTagGroup;
    return tagGroup || null;
  }

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
  }

  get categoryNames(): string[] {
    const categoryData = this.vars.get<string>(Attrs.categoryData);
    const categoryDataObj = JSON.parse(categoryData);
    if(!categoryDataObj || categoryDataObj.length === 0) return [];
    return categoryDataObj.map((category: any) => category.name);
  }

  render() {
    const url = this.vars.get<string>(Attrs.url);
    const target = this.vars.get<string>(Attrs.target);
    const type = this.vars.get<string>(Attrs.type);
    const morePadding = this.vars.get<string>(Attrs.morePadding);
    const contentMorePaddingClasses = morePadding ? "hhds-card__content--more-padding" : "";
    const footerMorePaddingClasses = morePadding ? "hhds-card__footer--more-padding" : "";

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-card hhds-card__${type}">

				${url ? `<a href="${url}" ${target ? `target="${target}"` : ""} class="hhds-card__link"></a>` : ""}

				<div class="hhds-card__header">	
					<slot name="image"></slot>
					<slot name="tags"></slot>
				</div>

				<div class="hhds-card__content ${contentMorePaddingClasses}">
					<slot></slot>
				</div>

				<div class="hhds-card__footer ${footerMorePaddingClasses}">
					<slot name="button"></slot>	
					<slot name="logo"></slot>	
				</div>

			</div>
		`;

    const footer = this.shadow.querySelector(".hhds-card__footer");
    const buttonSlot = footer?.querySelector('slot[name="button"]') as HTMLSlotElement;
    const logoSlot = footer?.querySelector('slot[name="logo"]') as HTMLSlotElement;

    const hasButtonContent = buttonSlot?.assignedElements().length > 0;
    const hasLogoContent = logoSlot?.assignedElements().length > 0;

    if (!hasButtonContent && !hasLogoContent) {
      footer?.remove();
    }
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.date]: {
    description: "A textual publish date",
    defaultValue: "",
    type: String,
  },
  [Attrs.type]: {
    description: "The type of card.",
    defaultValue: "",
    type: HHDSCardGridType,
  },
  [Attrs.title]: {
    description: "The title text.",
    defaultValue: "",
    type: String,
  },
  [Attrs.description]: {
    description: "Additional textual information.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cta]: {
    description: "An optional URL to navigate to when the item is clicked.",
    defaultValue: "",
    type: String,
  },
  [Attrs.url]: {
    description: "An optional URL to navigate to when the item is clicked.",
    defaultValue: "",
    type: String,
  },
  [Attrs.target]: {
    description: "Specifies where to open the URL (e.g., _blank for a new tab) if a URL is defined.",
    defaultValue: "",
    type: String,
  },
  [Attrs.categoryData]: {
    description: "The category data.",
    defaultValue: "",
    type: String,
  },
  [Attrs.morePadding]: {
    description: "Whether to add more padding to the card content and footer.",
    defaultValue: false,
    type: Boolean,
  },
};
