import css from "./image.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { Component } from "../../utils/Component";
import { HHDSCaption, HHDSCaptionTagName } from "../Caption/Caption";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "[HHDSImage]";
export const HHDSImageTagName: string = "hhds-image";
//const TAG_NAME: string = HHDSImageTagName;

const DEFER_ASPECT_CHANGE_UNTIL_IMAGE_LOAD: boolean = true;

export enum HHDSImageEvent {
  load = "hhds-image-load",
}

export const HHDSImageAttrNames = {
  src: "src",
  srcTablet: "src-tablet",
  srcMobile: "src-mobile",
  aspectTablet: "aspect-tablet",
  aspectMobile: "aspect-mobile",
  aspect: "aspect",
  alt: "alt",
  animate: "animate",
  srcSet: "srcset",
  sizes: "sizes",
};

const Attrs = HHDSImageAttrNames;

const Breakpoints = {
  xs: 0,
  sm: 640,
  md: 1024,
  lg: 1440,
  xl: 1920,
};

enum ImageVariantType {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
}

interface ImageVariant {
  src: string;
  mediaQuery: MediaQueryList;
  aspect: string | null;
}

const queryStringFromTo = (from: number, to: number): string =>
  `(min-width: ${from}px) and (max-width: ${to - 1}px)`;

const queryFromTo = (from: number, to: number): MediaQueryList =>
  window.matchMedia(queryStringFromTo(from, to));

export class HHDSImage extends Component {
  private mediaQueryChangeFunc: any;
  private imageLoadFunc: any;
  private imageVariants: { [key: string]: ImageVariant } = {};
  private pendingAspect: string | null = null;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
    this.mediaQueryChangeFunc = this.onMediaQueryChange.bind(this);
    this.imageLoadFunc = () => {
      DEFER_ASPECT_CHANGE_UNTIL_IMAGE_LOAD && this.applyPendingAspect();
      this.emitEvent(HHDSImageEvent.load);
    };
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const alt = this.vars.get<string>("alt");
    const animate = this.vars.get<boolean>("animate");

    let query: MediaQueryList;
    let aspect: string | null;
    if (this.vars.get(Attrs.srcMobile)?.length) {
      query = queryFromTo(Breakpoints.xs, Breakpoints.sm);
      aspect = this.vars.get<string>(Attrs.aspectMobile);
      this.imageVariants[ImageVariantType.mobile] = {
        src: this.vars.get<string>(Attrs.srcMobile),
        mediaQuery: query,
        aspect: aspect?.length ? aspect : null,
      };
      query.addEventListener("change", this.mediaQueryChangeFunc);
    }
    if (this.vars.get(Attrs.srcTablet)?.length) {
      query = queryFromTo(Breakpoints.sm, Breakpoints.lg);
      aspect = this.vars.get<string>(Attrs.aspectTablet);
      this.imageVariants[ImageVariantType.tablet] = {
        src: this.vars.get<string>(Attrs.srcTablet),
        mediaQuery: query,
        aspect: aspect?.length ? aspect : null,
      };
      query.addEventListener("change", this.mediaQueryChangeFunc);
    }

    if (Object.keys(this.imageVariants).length > 0) {
      query = window.matchMedia(`(min-width: ${Breakpoints.md}px)`);
      aspect = this.vars.get<string>(Attrs.aspect);
      this.imageVariants[ImageVariantType.desktop] = {
        src: this.vars.get<string>(Attrs.src),
        mediaQuery: query,
        aspect: aspect?.length ? aspect : null,
      };
      query.addEventListener("change", this.mediaQueryChangeFunc);
    }

    this.shadow.innerHTML = /* html */ `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<figure class="hhds-image">
				<div class="hhds-image__container ${
          animate ? "hhds-image__container--animate" : ""
        }">
                    <img
                        alt="${alt}"
                        class="hhds-image__img"
                        ${this.markupResponsiveImages}
                    />
				</div>
				<slot></slot>
			</figure>
		`;

    /*let queries = Object.keys(this.imageVariants).map((key) => this.imageVariants[key]);
    if (queries.length) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Queries: ", queries);
    }*/

    const didUpdate: boolean = this.updateForCurrentMediaQuery();
    if (!didUpdate) {
      this.image.src = this.vars.get<string>(Attrs.src);
      if (this.vars.get<string>(Attrs.aspect)) {
        this.style.setProperty(
          "--image-aspect-ratio",
          this.vars.get<string>(Attrs.aspect)
        );
      }
    }

    this.image.addEventListener("load", this.imageLoadFunc);
  }

  get markupResponsiveImages() {
    const attrSrcSet = this.vars.get<string>(Attrs.srcSet);
    const attrSizes = this.vars.get<string>(Attrs.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  disableTabbing(): void {
    const caption = this.getSlotted<HHDSCaption>(HHDSCaptionTagName);
    if (caption) caption.tabIndex = -1;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    Object.keys(this.imageVariants).forEach((key) => {
      const query = this.imageVariants[key].mediaQuery;
      query?.removeEventListener("change", this.mediaQueryChangeFunc);
    });
    this.image.removeEventListener("load", this.imageLoadFunc);
  }

  private applyPendingAspect(): void {
    if (this.pendingAspect) {
      if (this.pendingAspect === "-") {
        this.style.removeProperty("--image-aspect-ratio");
      } else {
        this.style.setProperty("--image-aspect-ratio", this.pendingAspect);
      }
      this.pendingAspect = null;
    }
  }

  get image(): HTMLImageElement {
    return this.shadow.querySelector("img") as HTMLImageElement;
  }

  private onMediaQueryChange(): void {
    this.updateForCurrentMediaQuery();
  }

  private updateForCurrentMediaQuery(): boolean {
    const variant = this.getVariantForCurrentMediaQuery();
    if (variant && variant.src) {
      if (variant.src != this.image.src) {
        DEBUG_VERBOSE &&
          console.log(
            CLASS_NAME,
            "Media query img change: ",
            variant.src,
            variant.aspect
          );
        if (variant.aspect) {
          this.pendingAspect = variant.aspect;
        } else {
          this.pendingAspect = "-";
        }
        if (!DEFER_ASPECT_CHANGE_UNTIL_IMAGE_LOAD) this.applyPendingAspect();
        this.image.src = variant.src;
        return true;
      }
    }
    return false;
  }

  private getVariantForCurrentMediaQuery(): ImageVariant | null {
    const keys = Object.keys(this.imageVariants);
    if (keys.length === 0) return null;
    let matchingVariant: ImageVariant | null = null;
    Object.keys(this.imageVariants).forEach((key) => {
      const variant = this.imageVariants[key];
      if (variant.mediaQuery.matches) matchingVariant = variant;
    });
    return matchingVariant;
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description:
      "The source URL of the image. Supports formats the main img formats like jpg, png and webp and also svg.",
    defaultValue: "",
    type: String,
  },
  [Attrs.srcSet]: {
    description:
      "Defines the set of images we will allow the browser to choose between, and what size each image is. Supports formats the main img formats like jpg, png and webp and also svg.",
    defaultValue: "",
    type: String,
  },
  [Attrs.sizes]: {
    description:
      "Defines a set of media conditions (e.g. screen widths) and indicates what image size would be best to choose, when certain media conditions are true.",
    defaultValue: "",
    type: String,
  },
  [Attrs.aspect]: {
    description: "Optional aspect ratio for the image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.srcTablet]: {
    description: "Optional image URL for tablet layouts.",
    defaultValue: "",
    type: String,
  },
  [Attrs.aspectTablet]: {
    description: "Optional aspect ratio for the tablet image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.srcMobile]: {
    description: "Optional image URL for mobile layouts.",
    defaultValue: "",
    type: String,
  },
  [Attrs.aspectMobile]: {
    description: "Optional aspect ratio for the mobile image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.alt]: {
    description: "The alt text for the image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.animate]: {
    description: "Defines an animation",
    defaultValue: "false",
    type: Boolean,
  },
};
