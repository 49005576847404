import css from "./contactformlayout.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSCheckboxGroup } from "../CheckboxGroup/CheckboxGroup";
import { HHDSInput } from "../Input/Input";
import { HHDSCheckboxGroupBehaviour } from "../CheckboxGroup/CheckboxGroupBehaviour";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSContactFormLayout";
export const HHDSContactFormLayoutTagName: string = "hhds-contact-form-layout";
const TAG_NAME: string = HHDSContactFormLayoutTagName;

export class HHDSContactFormLayout extends Component {
  private formEl!: HTMLFormElement;

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
    this.shadow.innerHTML = /*html*/ `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
                <div class="container">
                    <div class="grid">
                        <div class="col-span-6 sm:col-span-4 md:col-span-6">
                            <div class="${TAG_NAME}-image-container">
                                <slot name="image"></slot>
                            </div>
                            <div class="${TAG_NAME}-annotation-container">
                                <slot></slot>
                            </div>
                        </div>

                        <div class="col-span-6 sm:col-span-4 md:col-span-6">
                            <form action="${this.vars.get("action")}" method="post" id="form-office">
                                <fieldset class="${TAG_NAME}-fieldset" data-desc="details">
                                    <hhds-input required="true" error-message="add your first name" id="details-name-first" label="first name" type="text" name="first_name"></hhds-input>
                                    <hhds-input required="true" error-message="add your last name" id="details-name-second" label="last name" type="text" name="last_name"></hhds-input>
                                    <hhds-input required="true" error-message="add your email" id="details-email" label="email" name="email" type="email"></hhds-input>
                                    <hhds-input required="true" error-message="add your phone no." id="details-phone" label="phone no." name="phone" type="tel"></hhds-input>
                                </fieldset>
                                <hhds-checkbox-group
                                    id="relocation"
                                    label="I am interested in relocation to"
                                    name="relocation"
                                    required="true"
                                    error-message="Select at least one"
                                >
                                    <hhds-checkboxbutton id="relocation-texas" name="relocation-texas" value="texas">Texas</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="relocation-maryland" name="relocation-maryland" value="maryland">Maryland</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="relocation-nevada" name="relocation-nevada" value="nevada">Nevada</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="relocation-arizona" name="relocation-arizona" value="arizona">Arizona</hhds-checkboxbutton>
                                </hhds-checkbox-group>
                                <hhds-checkbox-group
                                    id="description"
                                    label="I am a"
                                    name="description"
                                    required="true"
                                    error_message="Select one"
                                    behaviour="${HHDSCheckboxGroupBehaviour.Radio}"
                                >
                                    <hhds-checkboxbutton id="description-site-selector" name="description-site-selector" value="site-selector">Site Selector</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="description-business-owner" name="description-business-owner" value="business-owner">Business Owner</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="description-c-suite" name="description-c-suite" value="c-suite-officer">C-Suite Officer</hhds-checkboxbutton>
                                    <hhds-checkboxbutton id="description-other" name="description-other" value="other">Other</hhds-checkboxbutton>
                                </hhds-checkbox-group>
                                <fieldset class="${TAG_NAME}-fieldset" data-desc="small-print">
                                    <slot name="terms"></slot>
                                </fieldset>
                                <input type="hidden" name="return_url" value="${this.vars.get("return_url")}" />
                                <hhds-button href="" target="_blank" type="primary" role="submit">Submit</hhds-button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
		`;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.formEl = this.shadowRoot?.querySelector("form") as HTMLFormElement;

    DEBUG_VERBOSE && console.log(CLASS_NAME, "init", `this.formEl`, this.formEl);

    if (!this.formEl) throw new Error(`${CLASS_NAME} - Unable to derive form in component!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.addListeners();
  }

  private addListeners() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.addListeners.name);

    const submitBtn = this.shadow.querySelector("hhds-button");
    submitBtn?.addEventListener("click", this.handleOnSubmit.bind(this));
  }

  handleOnSubmit(event: Event) {
    console.log(CLASS_NAME, this.handleOnSubmit.name, { event });

    event.preventDefault();

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.validate();
  }

  private validate() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.validate.name);

    if (!this.shadowRoot) throw new Error(`${CLASS_NAME} - Unable to derive shadowRoot!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Debug log data

    const formData = new FormData(this.formEl);

    // Log each key-value pair in the FormData
    for (let [key, value] of formData.entries()) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, this.validate.name, `${key}: ${value}`);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Test each input or group

    const validationStates: boolean[] = [];

    const inputsEls = Array.from(this.shadowRoot.querySelectorAll("hhds-input")) as HHDSInput[];

    inputsEls.forEach((inputEl) => {
      validationStates.push(inputEl.isValid());
    });

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.validate.name, {
        inputsEls,
        validationStates,
      });

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const relocationEl = this.shadowRoot.querySelector(
      'hhds-checkbox-group[name="relocation"]'
    ) as HHDSCheckboxGroup;

    const descriptionEl = this.shadowRoot.querySelector(
      'hhds-checkbox-group[name="description"]'
    ) as HHDSCheckboxGroup;

    validationStates.push(relocationEl.isValid(), descriptionEl.isValid());

    const formIsValid = validationStates.every((value) => value === true);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.addListeners.name, {
        validationStates,
        formIsValid,
      });

    if (formIsValid) {
      this.submit();
    }
  }

  private submit() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.submit.name);

    this.formEl.submit();
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, { _slot, elements });
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  action: {
    description: "URL to submit the form data to.",
    defaultValue: "",
    typeString: "action",
    type: String,
  },
  return_url: {
    description: "URL to return to after form submission.",
    defaultValue: "",
    typeString: "return_url",
    type: String,
  },
};
