import css from "./homesmap.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

import { HomesMapOverlay } from "./HomesMapOverlay";

import { Loader } from "@googlemaps/js-api-loader";
import { GradientAnimator } from "./GradientAnimator";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSHomesMap";
export const HHDSHomesMapTagName: string = "hhds-homesmap";
const TAG_NAME: string = HHDSHomesMapTagName;

export const HHDSHomesMapAttrNames = {
  key: "key",
  image: "image",
};

export enum HHDSHomesMapEvent {
  pan = "hhds-homesmap-pan",
  developmentClick = "hhds-homesmap-development-click",
}

interface ZoomSettings {
  zoom: number;
  minZoom: number;
  maxZoom: number;
}

interface DevelopmentState {
  selected: boolean;
  over: boolean;
  focused: boolean;
  selectedViaKeyboard: boolean;
}

const Attrs = HHDSHomesMapAttrNames;
export class HHDSHomesMap extends Component {
  private overlayImage: HTMLElement | null = null;
  private didPanSinceDevelopmentPress: boolean = false;
  private panBoundsPerBreakpoint: { [key: string]: google.maps.LatLngBounds } = {};
  private zoomPerBreakpoint: { [key: string]: ZoomSettings } = {};
  private gradientAnimators: { [key: string]: GradientAnimator } = {};
  private developmentState: { [key: string]: DevelopmentState } = {};
  private map: any;

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
          <div class="hhds-homesmap-overlay"> 
            ${HomesMapOverlay}
          </div>
          <div class="hhds-homesmap-container"></div>
      </div>
		`;

    // Grab the SVG
    this.overlayImage = this.shadow.querySelector(".hhds-homesmap-overlay") as HTMLElement;
    this.overlayImage!.style.visibility = "hidden";
    this.initGoogleMaps();
    this.createGradientAnimators();
    this.configureDevelopments();
  }

  unselectDevelopments(exceptDevelopmentId: string | null = null) {
    Object.keys(this.developmentState).forEach((developmentId) => {
      if (exceptDevelopmentId && developmentId === exceptDevelopmentId) return;
      const state = this.developmentState[developmentId];
      state.selected = false;
      //if (!state.over) {
      this.gradientAnimators[developmentId].play(GradientAnimator.Direction.back);
      this.updateDevelopmentLabel(developmentId, false);
      //}
    });
  }

  private createGradientAnimators() {
    // For each development, find a gradient with a corresponding id. If it exists,
    // store a GradientAnimator and animate that on development pointerover.
    const developments = this.overlayImage?.querySelectorAll(".development") as NodeListOf<HTMLElement>;
    const developmentIds = Array.from(developments).map((development) => development.id);
    developmentIds.forEach((developmentId) => {
      const gradientId = developmentId.replace("development-", "gradient-");
      let gradient = this.shadow.querySelector(`#${gradientId}`) as SVGLinearGradientElement;
      if (gradient) {
        const gradientAnimator = new GradientAnimator(gradient, {
          start: ["#e2e2e2", "#e2e2e2"],
          end: ["#00a7ff", "#47baa3"],
        });
        this.gradientAnimators[developmentId] = gradientAnimator;
      }
    });
  }

  async initGoogleMaps() {
    const loader = new Loader({
      apiKey: this.vars.get<string>("key"),
      version: "weekly",
    });

    const mapOptions = {
      center: { lat: 33.59018486269875, lng: -112.71496494971892 },
      zoom: 15,
      disableDefaultUI: true,
      draggable: true,
      minZoom: 15,
      maxZoom: 15,
      draggableCursor: "default", // Cursor when hovering over the map
      draggingCursor: "default", // Cursor while dragging the map
      scrollwheel: false,
      styles: [
        {
          featureType: "all",
          stylers: [{ visibility: "off" }],
        },
        { elementType: "geometry", stylers: [{ visibility: "on" }, { color: "#F2F2F2" }] },
      ],
    };

    await loader.load();

    class SVGOverlay extends google.maps.OverlayView {
      private bounds: google.maps.LatLngBounds;
      private image: HTMLElement | null = null;

      constructor(bounds: google.maps.LatLngBounds, image: HTMLElement, map: google.maps.Map) {
        super();
        this.bounds = bounds;
        this.image = image;
        this.setMap(map);
      }

      onAdd(): void {
        // Create a container div for the SVG
        // this.div = document.createElement("div");
        if (this.image) {
          this.image.style.position = "absolute";
          this.image.style.pointerEvents = "auto";
        }

        // Insert the SVG content
        // this.div.innerHTML = this.svgContent;

        // Allow interaction with the SVG
        // this.div.style.pointerEvents = "auto";

        // Append the container div to the overlay pane
        const panes = this.getPanes();
        if (this.image && panes && panes.overlayLayer) {
          panes.overlayMouseTarget.appendChild(this.image);
        }
      }

      draw(): void {
        if (!this.image) {
          return;
        }

        // Get the projection to calculate pixel positions
        const overlayProjection = this.getProjection();
        if (!overlayProjection) return;

        const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
        const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

        if (sw && ne) {
          // Position and size the SVG container
          this.image.style.left = `${sw.x}px`;
          this.image.style.top = `${ne.y}px`;
          this.image.style.width = `${ne.x - sw.x}px`;
          this.image.style.height = `${sw.y - ne.y}px`;
        }
      }

      onRemove(): void {
        if (this.image) {
          this.image.parentNode?.removeChild(this.image);
          this.image = null;
        }
      }
    }

    const container = this.shadow.querySelector(".hhds-homesmap-container") as HTMLElement;
    if (container) {
      this.map = new google.maps.Map(container, mapOptions);
      this.map.addListener("bounds_changed", () => {
        this.didPanSinceDevelopmentPress = true;
        this.emitEvent(HHDSHomesMapEvent.pan);
      });

      google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
        this.overlayImage!.style.visibility = "visible";
      });
    }

    const northeast = new google.maps.LatLng(33.61289, -112.68162);
    const southwest = new google.maps.LatLng(
      northeast.lat() - 0.038, // Subtract a latitude offset
      northeast.lng() - 0.067 // Subtract a longitude offset
    );
    const overlayBounds = new google.maps.LatLngBounds(southwest, northeast);

    if (this.overlayImage && this.map) {
      new SVGOverlay(overlayBounds, this.overlayImage, this.map);
    }

    window.addEventListener("resize", () => {
      if (this.map) {
        google.maps.event.trigger(this.map, "resize");
      }
    });

    const createPanBounds = (paddingLon: number, paddingLat: number) => {
      let panNe = new google.maps.LatLng(northeast.lat() + paddingLat, northeast.lng() + paddingLon);
      let panSw = new google.maps.LatLng(southwest.lat() - paddingLat, southwest.lng() - paddingLon);
      return new google.maps.LatLngBounds(panSw, panNe);
    };
    this.panBoundsPerBreakpoint["xs"] = createPanBounds(0.034, 0.034);
    this.panBoundsPerBreakpoint["sm"] = createPanBounds(0.017, 0.013);
    this.panBoundsPerBreakpoint["md"] = createPanBounds(0.02, 0.002);
    this.panBoundsPerBreakpoint["lg"] = this.panBoundsPerBreakpoint["md"];
    this.panBoundsPerBreakpoint["xl"] = createPanBounds(0.03, 0.002);

    this.zoomPerBreakpoint["xs"] = { zoom: 13.2, minZoom: 13.2, maxZoom: 15.5 };
    this.zoomPerBreakpoint["sm"] = { zoom: 14.3, minZoom: 14.3, maxZoom: 15.5 };
    this.zoomPerBreakpoint["md"] = { zoom: 15, minZoom: 15, maxZoom: 16 };
    this.zoomPerBreakpoint["lg"] = this.zoomPerBreakpoint["md"];
    this.zoomPerBreakpoint["xl"] = { zoom: 14, minZoom: 14, maxZoom: 16 };

    this.observeBreakpointChanges((breakpoint: string) => this.updateForBreakpoint(breakpoint));
    this.updateForBreakpoint();
  }

  private updateForBreakpoint(breakpoint: string | null = null) {
    if (!breakpoint) breakpoint = this.currentBreakpoint;

    const panBounds = this.panBoundsPerBreakpoint[breakpoint ?? "lg"];
    const zoomSettings = this.zoomPerBreakpoint[breakpoint ?? "lg"];
    const minZoom = zoomSettings.minZoom;
    const maxZoom = zoomSettings.maxZoom;
    const zoom = zoomSettings.zoom;

    // temporarily remove minZoom and maxZoom
    this.map.setOptions({
      minZoom: null,
      maxZoom: null,
    });

    if (breakpoint === "xs") {
      this.map.setOptions({
        disableDefaultUI: false,
        streetViewControl: false,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zoom: zoom,
        restriction: {
          latLngBounds: panBounds,
          strictBounds: true,
        },
      });
    } else {
      this.map.setOptions({
        disableDefaultUI: true,
        streetViewControl: false,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        minZoom: minZoom,
        maxZoom: maxZoom,
        zoom: zoom,
        restriction: {
          latLngBounds: panBounds,
          strictBounds: true,
        },
      });
    }
  }

  rehighlightDevelopmentIfRequired(developmentId: string) {
    if (this.developmentState[developmentId].selectedViaKeyboard) {
      const gradientAnimator = this.gradientAnimators[developmentId];
      gradientAnimator?.play(GradientAnimator.Direction.forward);
      this.updateDevelopmentLabel(developmentId, true);
    }
  }

  private updateDevelopmentLabel(developmentId: string, selected: boolean): void {
    const development = this.overlayImage?.querySelector(`#${developmentId}`) as HTMLElement;

    if (!development) return;
    const labelId = developmentId.replace("development-", "label-");
    const labelEl = this.overlayImage?.querySelector(`#${labelId}`) as HTMLElement;
    const labelPaths = labelEl?.querySelectorAll("path");
    labelPaths?.forEach((path) => {
      path.style.fill = selected ? "#ffffff" : "#727272";
    });
  }

  private configureDevelopments() {
    const developments = this.overlayImage?.querySelectorAll(".development") as NodeListOf<HTMLElement>;
    developments?.forEach((development) => {
      this.developmentState[development.id] = {
        selected: false,
        over: false,
        focused: false,
        selectedViaKeyboard: false,
      };

      const developmentId = development.id;
      const gradientAnimator = this.gradientAnimators[developmentId];
      development.addEventListener("pointerover", (_event: any) => {
        this.developmentState[developmentId].over = true;
        gradientAnimator?.play(GradientAnimator.Direction.forward);
        this.updateDevelopmentLabel(developmentId, true);
      });
      development.addEventListener("pointerout", (_event: any) => {
        this.developmentState[developmentId].over = false;
        if (!this.developmentState[developmentId].selected) {
          gradientAnimator?.play(GradientAnimator.Direction.back);
          this.updateDevelopmentLabel(developmentId, false);
        }
      });

      development.addEventListener("pointerdown", (_event: any) => {
        this.didPanSinceDevelopmentPress = false;
      });
      development.tabIndex = 0;
      development.addEventListener("focus", (_event: any) => {
        this.developmentState[developmentId].focused = true;
        gradientAnimator?.setGradient(GradientAnimator.Direction.forward);
        this.updateDevelopmentLabel(developmentId, true);
      });
      development.addEventListener("blur", (_event: any) => {
        this.developmentState[developmentId].focused = false;
        if (!this.developmentState[developmentId].selected) {
          gradientAnimator?.play(GradientAnimator.Direction.back);
          this.updateDevelopmentLabel(developmentId, false);
        }
      });
      development.addEventListener("keydown", (event: any) => {
        if (event.key === "Enter") {
          this.unselectDevelopments(development.id);
          this.developmentState[development.id].selected = true;
          this.developmentState[development.id].selectedViaKeyboard = true;
          this.emitEvent(HHDSHomesMapEvent.developmentClick, {
            id: development.id,
            developmentEl: development,
          });
        }
      });
      development.addEventListener("click", (event: any) => {
        if (!this.didPanSinceDevelopmentPress) {
          this.developmentState[developmentId].over = true;
          gradientAnimator?.play(GradientAnimator.Direction.forward);
          this.updateDevelopmentLabel(developmentId, true);
          this.developmentState[developmentId].selected = true;
          this.developmentState[development.id].selectedViaKeyboard = false;
          const targetDevelopment = event.target.closest(".development");
          this.unselectDevelopments(development.id);
          this.emitEvent(HHDSHomesMapEvent.developmentClick, {
            id: targetDevelopment.id,
            developmentEl: development,
          });
        }
      });
    });
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.key]: {
    description: "Google Maps API key.",
    defaultValue: "",
    type: String,
  },
  [Attrs.image]: {
    description: "Overlay image url.",
    defaultValue: "",
    type: String,
  },
};
