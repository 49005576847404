import css from "./headingandtext.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { BlockBackgroundColour } from "../../Constants";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSHeadingAndText";
export const HHDSHeadingAndTextTagName: string = "hhds-headingandtext";
const TAG_NAME: string = HHDSHeadingAndTextTagName;

export const HHDSHeadingAndTextAttrNames = {
  layout: "layout",
  background: "background",
  bgImage: "bg-image",
  extraStyles: "extra-styles",
};

const Attrs = HHDSHeadingAndTextAttrNames;

export enum HHDSHeadingAndTextLayout {
  left = "left",
  centered = "centered",
}

const sharedLayoutClasses =
  "col-span-6 sm:col-span-8 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6";

export class HHDSHeadingAndText extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const attrLayout = this.vars.get<number>(Attrs.layout);
    const bgImage = this.vars.get<string>(Attrs.bgImage);

    let backgroundAttr = "";
    const background = this.vars.get<number>(Attrs.background);
    backgroundAttr = `background="${background}"`;

    let imageBgColor = "";
    switch (background) {
      case BlockBackgroundColour.grey:
        imageBgColor = "var(--color-neutral-grey-100)";
        break;
      case BlockBackgroundColour.white:
        imageBgColor = "var(--color-neutral-white)";
        break;
      case BlockBackgroundColour.black:
        imageBgColor = "var(--color-neutral-grey-1000)";
        break;
      default:
        imageBgColor = "rgba(0, 0, 0, 0)";
    }

    let bgImageHtml = "";
    if (bgImage?.length > 0) {
      bgImageHtml = `<img class="bg-image" src="${bgImage}" role="presentation" style="--headingandtext-bg-image-background-color: ${imageBgColor}"/>`;
    }

    this.shadow.innerHTML = /* html */ `
<style>${globalStyles}</style>
<style>${css}</style>
${bgImageHtml}
<div class="${TAG_NAME}" layout="${attrLayout}" ${backgroundAttr}>
    <div class="container">
        <div class="grid">
            <div class="${TAG_NAME}__content ${sharedLayoutClasses}">
            <slot></slot>
        </div>
        <div class="${TAG_NAME}__cta ${sharedLayoutClasses}">
            <slot name="cta"></slot>
        </div>
    </div>
</div>
`;

    const mainDiv = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    const extraStyles = this.vars.get<string>(Attrs.extraStyles);
    mainDiv.style.cssText = extraStyles;
    if (bgImage?.length > 0) {
      mainDiv.style.setProperty("--headingandtext-background-color", "rgba(0, 0, 0, 0)");
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.extraStyles]: {
    description: "Extra styles to apply to the main div",
    defaultValue: "",
    type: String,
  },
  [Attrs.layout]: {
    description: "Text alignment",
    defaultValue: HHDSHeadingAndTextLayout.left,
    type: HHDSHeadingAndTextLayout,
  },
  [Attrs.background]: {
    description: "The background color",
    defaultValue: BlockBackgroundColour.white,
    type: BlockBackgroundColour,
  },
  [Attrs.bgImage]: {
    description: "The background image src",
    defaultValue: "",
    type: String,
  },
};
