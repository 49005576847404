export const EMPTY_STRING = "";

export enum LinkTarget {
  self = "_self",
  blank = "_blank",
}

export enum BlockBackgroundColour {
  white = "white",
  grey = "grey",
  black = "black",
}

export enum BlockBackgroundColourExpanded {
  white = "white",
  grey = "grey",
  green = "green",
  blue = "blue",
  black = "black",
}
