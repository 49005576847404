import css from "./imageandtext.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import {
  BlockBackgroundColour,
  EMPTY_STRING,
  LinkTarget,
} from "../../Constants";

import { HHDSImageAttrNames, ArgSpecs as ImageArgSpecs } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageAndText";
export const HHDSImageAndTextTagName: string = "hhds-image-and-text";
const TAG_NAME: string = HHDSImageAndTextTagName;

export const HHDSImageAndTextAttrNames = {
  layout: "layout",
  badge: "badge",
  header: "header",
  body: "body",
  ctaUrl: "cta-url",
  ctaLabel: "cta-label",
  ctaTarget: "cta-target",
  background: "background",
};

const Attrs = HHDSImageAndTextAttrNames;

export enum HHDSImageAndTextLayout {
  layoutA = "layout-a",
  layoutB = "layout-b",
  layoutC = "layout-c",
  layoutD = "layout-d",
  layoutE = "layout-e",
}

export class HHDSImageAndText extends Component {
  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const attrLayout = this.vars.get<number>(Attrs.layout);
    const attrBackground = this.vars.get<number>(Attrs.background);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
    this.shadow.innerHTML = /* html */ `
<style>${globalStyles}</style>
<style>${css}</style>
<section ${this.hasFullwidthBackground && `class="fullwidth" background="${attrBackground}"`}>
    <div class="${TAG_NAME} container" layout="${attrLayout}" ${!this.hasFullwidthBackground && `background="${attrBackground}"`}>
        <div class="grid">
        ${this.markupText}
        ${this.markupImage}
        ${this.markupTextAlt}
        </div>
    </div>
</section>
`;
  }

  // ////////////////////////////////////////////////////////////////////

  get hasFullwidthBackground() {
    // Handle any layout that should have a full-width background colour
    const attrLayout = this.vars.get<number>(Attrs.layout);

    return attrLayout === HHDSImageAndTextLayout.layoutA;
  }

  // ////////////////////////////////////////////////////////////////////

  get markupText() {
    const attrHeader = this.vars.get<number>(Attrs.header);

    if (!attrHeader) throw new Error("No header!");

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const attrBadge = this.vars.get<number>(Attrs.badge);
    const attrBody = this.vars.get<number>(Attrs.body);

    const attrCtaUrl = this.vars.get<number>(Attrs.ctaUrl);
    const attrCtaLabel = this.vars.get<number>(Attrs.ctaLabel);
    const attrCtaTarget = this.vars.get<number>(Attrs.ctaTarget);

    const attrLayout = this.vars.get<number>(Attrs.layout);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const attrBackground = this.vars.get<number>(Attrs.background);

    const mode = attrBackground === "black" ? "dark" : "light";

    switch (attrLayout) {
      case HHDSImageAndTextLayout.layoutA:
        return /* html */ `
        <hhds-richtext ref="text">
            <h1 class="heading heading--04">${attrHeader}</h1>
        </hhds-richtext>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      case HHDSImageAndTextLayout.layoutB:
        return /* html */ `
        <hhds-richtext ref="text">
            <h2 class="heading heading--05">${attrHeader}</h2>
        </hhds-richtext>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case HHDSImageAndTextLayout.layoutC:
        return /* html */ `
        <div class="wrapper" ref="text">
            <hhds-richtext>
                <h3 class="header">${attrHeader}</h3>
                ${attrBody ? `<p class="bodytext--03 body">${attrBody}</p>` : EMPTY_STRING}
                ${attrCtaUrl && attrCtaLabel && attrCtaTarget ? `<hhds-button class="link" href="${attrCtaUrl}" target="${attrCtaTarget}" type="primary" mode="${mode}">${attrCtaLabel}</hhds-button>` : EMPTY_STRING}
            </hhds-richtext>
        </div>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case HHDSImageAndTextLayout.layoutD:
        return /* html */ `
        <hhds-richtext ref="text">
            ${attrBadge ? `<p class="eyebrow eyebrow--01">${attrBadge}</p>` : EMPTY_STRING}
            <h4 class="header">${attrHeader}</h4>
            ${attrBody ? `<p class="bodytext--03 body">${attrBody}</p>` : EMPTY_STRING}
        </hhds-richtext>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case HHDSImageAndTextLayout.layoutE:
        return /* html */ `
        <hhds-richtext ref="text">
            <h3 class="header">${attrHeader}</h3>
            ${attrBody ? `<p class="bodytext--03 body">${attrBody}</p>` : EMPTY_STRING}
        </hhds-richtext>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      default:
        return EMPTY_STRING;
    }
  }

  get markupTextAlt() {
    const attrBody = this.vars.get<number>(Attrs.body);
    const attrLayout = this.vars.get<number>(Attrs.layout);

    switch (attrLayout) {
      case HHDSImageAndTextLayout.layoutD:
        return /* html */ `
            <hhds-richtext ref="text-alt">
                ${attrBody ? `<p class="bodytext--03 body">${attrBody}</p>` : EMPTY_STRING}
            </hhds-richtext>`;

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      default:
        return EMPTY_STRING;
    }
  }

  // ////////////////////////////////////////////////////////////////////

  get markupImage() {
    const attrSrc = this.vars.get<string>(HHDSImageAttrNames.src);
    const attrAlt = this.vars.get<string>(HHDSImageAttrNames.alt);

    const attrAspect = this.vars.get<string>(HHDSImageAttrNames.aspect);

    if (attrSrc) {
      return /* html */ `<hhds-image
                            src="${attrSrc}"
                            ${this.markupResponsiveImages}
                            aria-label="${attrAlt}"
                            alt="${attrAlt}"
                            ref="image" ${attrAspect ? `${HHDSImageAttrNames.aspect}="${attrAspect}"` : EMPTY_STRING}
                            style="--image-border-top-left-radius: 0; --image-border-top-right-radius: 0; --image-border-bottom-left-radius: 0; --image-border-bottom-right-radius: 0;"
                        ></hhds-image>`;
    } else {
      return EMPTY_STRING;
    }
  }

  get markupResponsiveImages() {
    const attrSrcSet = this.vars.get<string>(HHDSImageAttrNames.srcSet);
    const attrSizes = this.vars.get<string>(HHDSImageAttrNames.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  // ////////////////////////////////////////////////////////////////////

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  /*my-enum: {
    description: "Some enum-based argument.",
    defaultValue: MyEnum.myValue,
    typeString: "MyEnum",
    type: MyEnum,
  },*/
  [Attrs.layout]: {
    description: "",
    defaultValue: HHDSImageAndTextLayout.layoutA,
    type: HHDSImageAndTextLayout,
  },
  [Attrs.badge]: {
    description: "The badge copy",
    defaultValue: "",
    type: String,
  },
  [Attrs.header]: {
    description: "The header copy",
    defaultValue: "",
    type: String,
  },
  [Attrs.body]: {
    description: "The body copy",
    defaultValue: "",
    type: String,
  },
  [Attrs.ctaUrl]: {
    description: "The call to action URL",
    defaultValue: "",
    type: String,
  },
  [Attrs.ctaLabel]: {
    description: "The call to action label",
    defaultValue: "",
    type: String,
  },
  [Attrs.ctaTarget]: {
    description: "The call to action target",
    defaultValue: LinkTarget.self,
    type: LinkTarget,
  },
  [Attrs.background]: {
    description: "The background color",
    defaultValue: BlockBackgroundColour.black,
    type: BlockBackgroundColour,
  },
  ...ImageArgSpecs,
};
