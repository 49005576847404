import css from "./segmentedcontrol.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSSegmentedControl";
export const HHDSSegmentedControlTagName: string = "hhds-segmented-control";
const TAG_NAME: string = HHDSSegmentedControlTagName;

export const HHDSSegmentedControlAttrNames = {
  name: "name",
  selected: "selected",
};

const Attrs = HHDSSegmentedControlAttrNames;

export class HHDSSegmentedControl extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">HELLO</div>
      <slot></slot>
		`;

    this.observeSlotChanges(true);
    this.attachFormDataListener();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
    if (elements.length === 0) return;
    let newInnerHtml: string = "";
    elements.map((element, index) => {
      const label = element.textContent;
      const value = element.getAttribute("value") || `value-${index}`;
      if (label) newInnerHtml += this.createSegmentHtml(index, value, label);
    });
    const componentDiv = this.shadow.querySelector(`.${TAG_NAME}`);
    if (componentDiv) componentDiv.innerHTML = newInnerHtml;

    const firstInput = this.shadow.querySelector("input");
    if (firstInput) firstInput.setAttribute("checked", "");

    const inputs = this.shadow.querySelectorAll("input");
    inputs.forEach((input) => input.addEventListener("change", this.onSegmentChange.bind(this)));
    const labels = this.shadow.querySelectorAll("label");
    // add the ability to select the segment via keyboard
    labels.forEach((label) => {
      label.addEventListener("keydown", (e) => {
        if (e.key === "Enter" || e.key === " ") {
          const input = label.previousElementSibling as HTMLInputElement;
          input.checked = true;
          input.dispatchEvent(new Event("change"));
        }
      });
    });

    slot.remove();

    const selected = this.vars.get<string>(Attrs.selected);
    if (selected?.length) this.value = selected;
  }

  private onSegmentChange(event: Event): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "onSegmentChange:", event);
    this.emitEvent("change", event);
  }

  get value(): string {
    const input = this.shadow.querySelector("input:checked");
    return input ? input.getAttribute("value") || "" : "";
  }

  set value(newValue: string) {
    const input = this.shadow.querySelector(`input[value="${newValue}"]`);
    if (input) input.setAttribute("checked", "");
  }

  private createSegmentHtml(index: number, value: string, label: string): string {
    const name = this.vars.get<string>(Attrs.name);
    const htmlString = `
      <input name="${name}" type="radio" value="${value}" id="radio-button-${index}"/>
      <label class="segment uppercase uppercase--01" tabindex="0" for="radio-button-${index}">${label}</label>
    `;
    return htmlString;
  }

  attachFormDataListener() {
    const form = this.closest("form");
    if (!form) return;
    form.addEventListener("formdata", (event) => {
      const checkedInput = this.shadow.querySelector("input:checked");
      const data = checkedInput ? checkedInput.getAttribute("value") || "" : "false";
      const name = this.vars.get<string>(Attrs.name);
      event.formData.append(name, data);
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Form data", name, data);
      DEBUG_VERBOSE && console.log(CLASS_NAME, "event.formData now:", event.formData);
      console.log(event.formData);
    });
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.name]: {
    description: "The name of the segmented control",
    type: String,
    defaultValue: "segmented-control",
  },
  [Attrs.selected]: {
    description: "The selected segment",
    type: String,
    defaultValue: "",
  },
};
