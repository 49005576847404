import css from "./checkboxbutton.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSCheckboxButtonEvents } from "./CheckboxButtonEvents";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE = false;
const CLASS_NAME: string = "HHDSCheckboxButton";
const TAG_NAME: string = "hhds-checkboxbutton";
export const HHDSCheckboxButtonTagName: string = "hhds-checkboxbutton";

export const HHDSCheckboxButtonAttrNames = {
  checked: "checked",
  required: "required",
  value: "value",
  name: "name",
};

const Attrs = HHDSCheckboxButtonAttrNames;

export class HHDSCheckboxButton extends Component {
  private _checkboxEl: HTMLInputElement | null = null;
  public checked!: boolean;
  public value: string = "";

  constructor() {
    super();
  }

  protected override init(): void {
    this.render();
    this.attachFormDataListener();
  }

  private render(): void {
    const checked =
      this.checked !== undefined
        ? this.checked
        : this.vars.get<boolean>(Attrs.checked);

    this.value = this.vars.get<string>(Attrs.value);

    this.shadow.innerHTML = /* html */ `
	<style>${globalStyles}</style>
	<style>${css}</style>
	<div class="hhds-checkboxbutton">
		<input type="checkbox" class="hhds-checkboxbutton__input" ${checked ? "checked" : ""} value="${this.value}" id="checkbox"/>
		<span class="hhds-checkboxbutton__checkmark"></span>
	  	<label class="hhds-checkboxbutton__label bodytext bodytext--03 temp-body" for="checkbox"><slot></slot></label>
	</div>`;

    this._checkboxEl = this.shadow.querySelector('input[type="checkbox"]');
    this._checkboxEl?.addEventListener(
      "change",
      this.onCheckboxUpdated.bind(this)
    );

    /*const checkmark = this.shadow.querySelector(".hhds-checkboxbutton__checkmark");
    console.log("CHECKMARK: ", checkmark);
    checkmark?.addEventListener("keydown", (e: Event) => {
      console.log("KEY DOWN");
      const keyboardEvent = e as KeyboardEvent;
      if (keyboardEvent.key === "Enter" || keyboardEvent.key === " ") {
        this._checkbox?.click();
      }
    });*/
  }

  public isValid() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.isValid.name, this.checked);
    // DEBUG_VERBOSE && console.dir(this._checkbox);

    const attrRequired = this.vars.get<boolean>(Attrs.required);

    let isValidState;

    if (attrRequired === false) {
      isValidState = true;
    } else {
      isValidState = this.checked === undefined ? false : this.checked;
    }

    DEBUG_VERBOSE && console.log(CLASS_NAME, this.isValid.name, this.checked);

    return isValidState;
  }

  attachFormDataListener() {
    const form = this.closest("form");
    if (!form) return;

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.attachFormDataListener.name, form);

    form.addEventListener("formdata", (event) => {
      const name = this.vars.get<string>(Attrs.name);

      const { formData } = event;

      // Ensure not to duplicate data
      if (formData.has(name)) formData.delete(name);

      //   For the form data we return the value rather the checked state

      const data = this.checked ? this.value : "false";

      formData.append(name, data);

      DEBUG_VERBOSE &&
        console.log(CLASS_NAME, "Form data event. Appending: ", name, data);
    });
  }

  onCheckboxUpdated() {
    if (!this._checkboxEl) {
      throw new Error("No checkbox element");
    }

    this.checked = this._checkboxEl.checked;

    DEBUG_VERBOSE &&
      console.log(`[${TAG_NAME}] Checkbox status updated to ${this.checked}`);

    const detail = { target: this, checked: this.checked };
    // Create a hook so we can tell when value has changed
    const customEvent = new CustomEvent(HHDSCheckboxButtonEvents.Changed, {
      detail,
    });
    console.log({ customEvent });
    this.dispatchEvent(customEvent);
  }

  resetCheckedState() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.resetCheckedState.name);
    this.checked = false;
    this.render();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.name]: {
    description: "The name that is assigned to the checkbox",
    defaultValue: "",
    type: String,
  },
  [Attrs.value]: {
    description: "The value that is assigned when the checkbox is checked",
    defaultValue: "",
    type: String,
  },
  [Attrs.checked]: {
    description: "Whether the checkbox is checked or not",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.required]: {
    description: "Whether the checkbox is required",
    defaultValue: false,
    type: Boolean,
  },
};
