import css from "./imagecard.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSImageAttrNames, ArgSpecs as ImageArgSpecs } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageCard";
export const HHDSImageCardTagName: string = "hhds-imagecard";
const TAG_NAME: string = HHDSImageCardTagName;

export const HHDSImageCardAttrNames = {
  src: "src",
  title: "title",
  href: "href",
  target: "target",
  useHhdsImage: "use-hhds-image",
};

const Attrs = HHDSImageCardAttrNames;

export class HHDSImageCard extends Component {
  private USE_HHDS_IMAGE: boolean = false;

  private _title: string = "";
  private src: string = "";

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this._title = this.vars.get(Attrs.title);
    this.src = this.vars.get(Attrs.src);

    this.USE_HHDS_IMAGE = this.vars.get<string>(Attrs.useHhdsImage);

    const bgImageStyle = this.USE_HHDS_IMAGE
      ? ``
      : `background-image: url(${this.src});`;

    this.shadow.innerHTML = /* html */ `
			<style>${css}</style>
			<style>${globalStyles}</style>
			<div style="${bgImageStyle}" class="${TAG_NAME}">
                ${this.titleHtml}
                ${this.imageHtml}
            </div>`;

    const href = this.vars.get<string>(Attrs.href);
    const target = this.vars.get<string>(Attrs.target);

    if (href?.length) {
      const targetElement = this;
      targetElement.tabIndex = 0;
      targetElement.style.cursor = "pointer";
      targetElement.addEventListener("click", () =>
        window.open(href, target?.length ? target : "_self")
      );
      targetElement.addEventListener("keydown", (e) => {
        if (e.key === "Enter") targetElement.click();
      });
    }
  }

  get titleHtml(): string {
    if (this._title) {
      return /* html */ `
        <hhds-richtext>
          <h2 class="heading heading--02">${this._title}</h2>
        </hhds-richtext>
        <div class="hhds-imagecard__border"></div>
      `;
    } else {
      return "";
    }
  }

  get imageHtml(): string {
    return this.USE_HHDS_IMAGE
      ? /* html */ `<hhds-image animate="true" src="${this.src}" ${this.markupResponsiveImages} alt="${this._title}"></hhds-image>`
      : "";
  }

  get markupResponsiveImages() {
    const attrSrcSet = this.vars.get<string>(HHDSImageAttrNames.srcSet);
    const attrSizes = this.vars.get<string>(HHDSImageAttrNames.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "The URL of the image",
    defaultValue: "",
    type: String,
  },
  [Attrs.title]: {
    description: "The title of the card",
    defaultValue: "",
    type: String,
  },
  [Attrs.href]: {
    description: "The URL on click",
    defaultValue: "",
    type: String,
  },
  [Attrs.target]: {
    description: "The link target",
    defaultValue: "_self",
    type: String,
  },
  [Attrs.useHhdsImage]: {
    description: "Use the HHDS image component",
    defaultValue: true,
    type: Boolean,
  },
  [HHDSImageAttrNames.srcSet]: ImageArgSpecs[HHDSImageAttrNames.srcSet],
  [HHDSImageAttrNames.sizes]: ImageArgSpecs[HHDSImageAttrNames.sizes],
};
