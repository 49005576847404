import css from "./input.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSInputType } from "./InputType";
import { HHDSInputState } from "./InputState";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE = false;
const CLASS_NAME: string = "HHDSInput";
const TAG_NAME: string = "hhds-input";
export const HHDSInputTagName: string = "hhds-input";

export const HHDSInputAttrNames = {
  id: "id",
  required: "required",
  errorMessage: "error-message",
  showOptional: "show-optional",
  name: "name",
  label: "label",
  placeholder: "placeholder",
  value: "value",
  type: "type",
  state: "state",
  disabled: "disabled",
  showSpacer: "show-spacer",
};

const Attrs = HHDSInputAttrNames;

export class HHDSInput extends Component {
  private inputElement!: HTMLInputElement;
  private value: string | undefined = "";

  constructor() {
    super();
  }

  protected override init(): void {
    const attrId = this.vars.get<string>(Attrs.id);
    const attrRequired = this.vars.get<boolean>(Attrs.required);
    const attrErrorMessage = this.vars.get<string>(Attrs.errorMessage);
    const attrLabel = this.vars.get<string>(Attrs.label);
    const attrPlaceholder = this.vars.get<string>(Attrs.placeholder);
    const attrType = this.vars.get<HHDSInputType>(Attrs.type);
    const attrState = this.vars.get<HHDSInputState>(Attrs.state);
    const attrName = this.vars.get<string>(Attrs.name);

    let attrValue = this.vars.get<string>(Attrs.value);

    if (this.value) {
      DEBUG_VERBOSE &&
        console.log(CLASS_NAME, `Using fallback value of ${this.value}`);
      attrValue = this.value;
    }

    const disabled = this.vars.get<boolean>(Attrs.disabled);
    const hhdsInputClass = attrState === "error" ? "hhds-input--error" : "";
    const hhdsInputInputClass =
      attrLabel.length > 0 ? "hhds-input__input--label" : "";

    let searchSvg = ``;
    let searchClass = ``;
    if (attrType === HHDSInputType.Search) {
      searchClass = "hhds-input_lower--search";
      searchSvg = `<div class="search-svg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9422 16.0577L13.0305 12.1468C14.1643 10.7856 14.7297 9.03977 14.609 7.27238C14.4883 5.50499 13.6909 3.85217 12.3827 2.65772C11.0744 1.46328 9.35603 0.819195 7.58498 0.859445C5.81394 0.899695 4.12659 1.62118 2.87395 2.87383C1.62131 4.12647 0.899817 5.81382 0.859567 7.58486C0.819317 9.35591 1.46341 11.0743 2.65785 12.3825C3.85229 13.6908 5.50512 14.4882 7.2725 14.6089C9.03989 14.7295 10.7858 14.1642 12.1469 13.0304L16.0579 16.9421C16.1159 17.0002 16.1849 17.0463 16.2607 17.0777C16.3366 17.1091 16.4179 17.1253 16.5001 17.1253C16.5822 17.1253 16.6635 17.1091 16.7394 17.0777C16.8152 17.0463 16.8842 17.0002 16.9422 16.9421C17.0003 16.8841 17.0464 16.8151 17.0778 16.7392C17.1092 16.6634 17.1254 16.5821 17.1254 16.4999C17.1254 16.4178 17.1092 16.3365 17.0778 16.2606C17.0464 16.1848 17.0003 16.1158 16.9422 16.0577ZM2.12506 7.74993C2.12506 6.63741 2.45496 5.54988 3.07304 4.62485C3.69112 3.69982 4.56963 2.97885 5.59746 2.55311C6.6253 2.12737 7.7563 2.01598 8.84744 2.23302C9.93858 2.45006 10.9409 2.98579 11.7275 3.77246C12.5142 4.55913 13.0499 5.56141 13.267 6.65255C13.484 7.74369 13.3726 8.87469 12.9469 9.90253C12.5211 10.9304 11.8002 11.8089 10.8751 12.427C9.95011 13.045 8.86258 13.3749 7.75006 13.3749C6.25872 13.3733 4.82894 12.7801 3.77441 11.7256C2.71987 10.6711 2.12671 9.24127 2.12506 7.74993Z" fill="#707070"/></svg></div>`;
    }

    const classes = ["hhds-input__input", "form-text"]; //"body", "body--03"]; //
    let input = `<input
            ${disabled && "disabled"}
            ${attrId && `id="${attrId}"`}
            class="${classes.join(" ")}"
            ${attrType && `type="${attrType}"`}
            ${attrName && `name="${attrName}"`}
            ${attrPlaceholder && `placeholder="${attrPlaceholder}"`}
            ${attrValue && `value="${attrValue}"`}
            ${attrName && `name="${attrName}"`}
            ${attrLabel && `aria-label="${attrLabel}"`}
            ${attrId && `aria-describedby="${Component.getAriaDescriptionAttributeValueFromId(attrId)}"`}
            ${attrRequired && "required"}
            >`;

    let label = ``;
    if (attrLabel.length > 0) {
      label = `<label class="hhds-input__label label" aria-hidden="true" ${attrName && `for="${attrName}"`}>${attrLabel}</label>`;
    }

    let error = ``;
    if (attrState === HHDSInputState.Error) {
      error = `<span
            aria-hidden="true"
            ${attrId && `id="${Component.getAriaDescriptionAttributeValueFromId(attrId)}"`}
            style="display: ${attrState === "error" ? "block" : "none"};"
            class="hhds-input__required label">${attrErrorMessage}</span>`;
    }

    let optional = ``;
    if (this.vars.get<boolean>(Attrs.showOptional)) {
      optional = `<span class="hhds-input__optional label">Optional</span>`;
    }

    const showSpacer = this.vars.get<boolean>(Attrs.showSpacer);
    const labelOrErrorExists =
      attrLabel.length > 0 || attrState === HHDSInputState.Error;
    const showUpper = labelOrErrorExists || showSpacer;
    let extraUpperClasses = "";

    if (showUpper && attrLabel.length == 0) {
      extraUpperClasses += " hhds-input__upper--nolabel";
    }
    if (!labelOrErrorExists && showSpacer) {
      extraUpperClasses += " hhds-input__upper--spacer";
    }

    this.shadow.innerHTML = `
<style>${globalStyles}</style>
<style>${css}</style>
<div class="hhds-input">
    <div class="hhds-input__upper ${extraUpperClasses}" style="display: ${showUpper ? "flex" : "none"};">
          ${label}
          ${optional ? optional : error}
    </div>
    <div class="hhds-input__lower ${disabled ? "hhds-input__lower--disabled" : ""} ${hhdsInputClass} ${hhdsInputInputClass} ${searchClass}">
        ${input}
        ${searchSvg}
    </div>
</div>
		`;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // We need to set this.inputElement after every render as the isvalid method can trigger a re-render
    this.inputElement = this.shadow.querySelector("input") as HTMLInputElement;
    if (!this.inputElement)
      throw new Error(`${CLASS_NAME} - Unable to derive input element!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Store the value any time it's updated. This value is also used when the component re-renders

    this.inputElement.addEventListener("input", () => {
      this.value = this.inputElement.value;
      DEBUG_VERBOSE &&
        console.log(
          CLASS_NAME,
          this.connectedCallback.name,
          `[${TAG_NAME}] Value is now ` + this.value
        );
    });

    this.attachFormDataListener();
  }

  public isValid() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.isValid.name);
    DEBUG_VERBOSE && console.dir(this.inputElement);

    const attrRequired = this.vars.get<boolean>(Attrs.required);

    let isValidState;

    if (attrRequired === false) {
      isValidState = true;
    } else {
      isValidState = this.isTypeValid();
    }

    const nextAttrStateValue = isValidState
      ? HHDSInputState.Default
      : HHDSInputState.Error;

    this.setAttribute(Attrs.state, nextAttrStateValue);

    return isValidState;
  }

  isTypeValid() {
    const attrType = this.vars.get<HHDSInputType>(Attrs.type);

    const { value } = this.inputElement;

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.isTypeValid.name, { attrType, value });

    switch (attrType) {
      case HHDSInputType.Text:
        return value.length > 0 ? true : false;

      case HHDSInputType.Email:
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);

      case HHDSInputType.Tel:
        return /^[0-9]+$/.test(value);

      case HHDSInputType.Search:
        return value.length > 0 ? true : false;

      default:
        throw new Error(`Type: ${attrType} NOT handled!`);
    }
  }

  attachFormDataListener() {
    const form = this.closest("form");
    if (!form) return;
    form.addEventListener("formdata", (event) => {
      const name = this.vars.get<string>(Attrs.name);

      DEBUG_VERBOSE &&
        console.log(
          CLASS_NAME,
          "Form data event. Appending: ",
          name,
          this.inputElement ? this.inputElement.value : ""
        );

      const { formData } = event;

      // Ensure not to duplicate data
      if (formData.has(name)) formData.delete(name);

      formData.append(name, this.inputElement ? this.inputElement.value : "");
    });
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.id]: {
    description: "The id that is assigned to the input",
    defaultValue: "",
    type: String,
  },
  [Attrs.required]: {
    description: "The requried attribute that is assigned to the input",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.errorMessage]: {
    description: "The error message to be displayed when input is invalid",
    defaultValue: "Required",
    type: String,
  },
  [Attrs.name]: {
    description: "The name that is assigned to the input",
    defaultValue: "",
    type: String,
  },
  [Attrs.label]: {
    description: "The label that is assigned to the input",
    defaultValue: "",
    type: String,
  },
  [Attrs.placeholder]: {
    description: "The placeholder that is assigned to the input",
    defaultValue: "",
    type: String,
  },
  [Attrs.value]: {
    description: "The value that is assigned to the input",
    defaultValue: "",
    type: String,
  },
  [Attrs.type]: {
    description: "The input type",
    defaultValue: HHDSInputType.Text,
    type: HHDSInputType,
  },
  [Attrs.state]: {
    description: "The input state",
    defaultValue: HHDSInputState.Default,
    type: HHDSInputState,
  },
  [Attrs.disabled]: {
    description: "Whether the input is disabled",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.showSpacer]: {
    description: "Whether to pad the top even if there is no label or error",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.showOptional]: {
    description:
      "Whether to show the 'optional' text, can interfer with error messages.",
    defaultValue: false,
    type: Boolean,
  },
};
