import css from "./cardgrid.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSCardGridType, HHDSCardAspectRatio } from "./CardGrid.enums";
import { HHDSSelect, HHDSSelectTagName } from "../Select/Select";
import { HHDSCard, HHDSCardTagName } from "../Card/Card";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSCardGrid";
export const HHDSCardGridTagName: string = "hhds-cardgrid";

const ALL_CATEGORIES_LABEL: string = "All categories";

export const HHDSCardGridAttrNames = {
  type: "type",
  grid: "grid",
  cardAspect: "card-aspect",
  collapse: "collapse",
  paged: "paged",
  showFeatured: "show-featured",
};

const Attrs = HHDSCardGridAttrNames;
const CAROUSEL_BREAKPOINT = 640;

interface InsightAJAXResponse {
  remaining: number;
  posts: InsightAJAXPost[];
}

interface InsightAJAXPost {
  name: string;
  thumbnails: any;
  alt: string;
  description: string;
  external: string;
  link: string;
  post: string;
  categories: any[];
  date?: string;
}

interface WPAjaxObject {
  ajax_url: string;
  nonce: string;
}

declare var ajax_obj: WPAjaxObject;

export class HHDSCardGrid extends Component {
  private isGrid: boolean = true;
  private btnLoadMore: HTMLElement | null = null;
  private numLoaded: number = 0;
  private loading: boolean = false;
  private cards: HHDSCard[] = [];
  private loadMoreObserver: IntersectionObserver | null = null;
  private categoryFilter: string | null = null;

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    this.renderAsGrid();
    if (this.vars.get("collapse")) {
      this.addResizeListener();
      if (window.innerWidth < CAROUSEL_BREAKPOINT) {
        this.renderAsCarousel();
      }
    }
    this.observeSlotChanges(true);
    this.updateCardVisibilityForCategoryStatus();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.removeResizeListener();
    this.removeLoadMoreListener();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }

    const cards: HHDSCard[] = [];

    elements.forEach((element: Element) => {
      const htmlElement = element as HTMLElement;
      if (htmlElement.tagName.toLowerCase() === HHDSCardTagName) {
        cards.push(htmlElement as HHDSCard);
      }
      const select = htmlElement.querySelector(HHDSSelectTagName) as HHDSSelect;
      if (select) {
        select.addEventListener("selectChanged", (event) => {
          const select = event.target as HHDSSelect;
          if (select.value == ALL_CATEGORIES_LABEL) {
            this.filterByCategory(null);
          } else {
            this.filterByCategory(select.value);
          }
        });
      }
    });

    this.cards = cards;
  }

  private filterByCategory(category: string | null): void {
    this.categoryFilter = category;
    this.cards.forEach((card) => {
      card.style.display = this.cardContainsCategory(card, category) ? "flex" : "none";
    });
    this.updateCardVisibilityForCategoryStatus();
    this.updateFinalRowAlignment();
  }

  private cardContainsCategory(card: HHDSCard, category: string | null): boolean {
    if (!category) return true;
    const categoryNames = card.categoryNames;
    if (!categoryNames) return false;
    return categoryNames.includes(category);
  }

  private updateCardVisibilityForCategoryStatus(): void {
    const featuredDiv = this.shadow.querySelector(".hhds-cardgrid-featured") as HTMLElement;
    const showFeatured = this.categoryFilter == null;
    if (featuredDiv) featuredDiv.style.display = showFeatured ? "block" : "none";

    // Get all main cards from slotted content
    let mainSlot = this.shadow.querySelector(
      'slot:not([name="intro"]):not([name="featured"])'
    ) as HTMLSlotElement;
    let mainCards: any = mainSlot?.assignedElements({ flatten: true });
    mainCards = mainCards.filter((el: any) => el.tagName.toLowerCase() === "hhds-card");

    // Get all featured cards from slotted content
    let featuredSlot = this.shadow.querySelector('slot[name="featured"]') as HTMLSlotElement;
    let featuredCards: any = featuredSlot?.assignedElements({ flatten: true });
    featuredCards = featuredCards?.filter((el: any) => el.tagName.toLowerCase() === "hhds-card");

    // Get the ids of the featured cards
    const featuredCardIds = featuredCards?.map((card: any) => card.getAttribute("data-id"));

    // Update the display of the main cards based on the category filter and featured display status
    mainCards.forEach((card: HHDSCard) => {
      const cardId = card.getAttribute("data-id");
      if (showFeatured) {
        card.style.display = featuredCardIds?.includes(cardId) ? "none" : "flex";
      } else {
        card.style.display = this.cardContainsCategory(card, this.categoryFilter) ? "flex" : "none";
      }
    });
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  private addResizeListener() {
    window.addEventListener("resize", this.onResize);
  }

  private removeResizeListener() {
    window.removeEventListener("resize", this.onResize);
  }

  private onResize = () => {
    if (window.innerWidth < CAROUSEL_BREAKPOINT) {
      if (this.isGrid) {
        this.renderAsCarousel();
      }
    } else {
      if (!this.isGrid) {
        this.renderAsGrid();
      }
    }
  };

  private addInfiniteScrollListener() {
    if (this.btnLoadMore && !this.loadMoreObserver) {
      const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.loading) {
            observer.unobserve(entry.target);
            this.loadMore();
          }
        });
      };

      this.loadMoreObserver = new IntersectionObserver(callback, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });

      this.loadMoreObserver.observe(this.btnLoadMore);
    }
  }

  private removeInfiniteScrollListener() {
    if (this.loadMoreObserver) {
      this.loadMoreObserver.disconnect();
      this.loadMoreObserver = null;
    }
  }

  private removeLoadMoreListener() {
    this.btnLoadMore?.removeEventListener("click", this.loadMore.bind(this));
  }

  private async loadMore() {
    this.removeInfiniteScrollListener();
    if (this.btnLoadMore) {
      this.btnLoadMore.innerHTML = "Loading...";
    }
    const data: InsightAJAXResponse | null = await this.fetchMoreData(this.numLoaded);
    if (data && data.posts) {
      this.numLoaded += data.posts.length;
      this.renderFetchedData(data.posts);
      if (this.categoryFilter) {
        // Re-filter the posts
        this.filterByCategory(this.categoryFilter);
      }
      const remaining = data.remaining;
      if (remaining > 0 && this.btnLoadMore) {
        this.btnLoadMore.innerHTML = "Load More!!";
        this.addInfiniteScrollListener();
      } else {
        this.removeLoadMoreButton();
      }
    } else {
      this.removeLoadMoreButton();
    }
  }

  private async fetchMoreData(offset: number = 0): Promise<InsightAJAXResponse | null> {
    try {
      const response = await fetch(ajax_obj.ajax_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "fetch_more",
          offset: offset.toString(),
          nonce: ajax_obj.nonce,
        }),
      });

      const result = await response.json();

      if (result.success) {
        return result.data;
      } else {
        console.error("Fetch request failed.");
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch data.", error);
      return null;
    }
  }

  private renderFetchedData(data: InsightAJAXPost[]) {
    data.forEach((item) => {
      const card = document.createElement("hhds-card");

      card.classList.add(...this.vars.get("grid").split(" "));

      const aspect = this.vars.get("card-aspect") ? this.vars.get("card-aspect") : "unset";
      const object_fit = aspect == "unset" ? "fill" : "cover";

      card.setAttribute("label", item.name);
      card.setAttribute("description", item.description);
      card.setAttribute("target", item.external ? "_blank" : "_self");
      card.setAttribute("url", item.link);

      if (item.thumbnails?.base) {
        card.innerHTML = `
          <hhds-image src="${item.thumbnails.base}" srcset="
              ${item.thumbnails["640"]} 640w,
              ${item.thumbnails["960"]} 960w,
              ${item.thumbnails["1280"]} 1280w
            " size="
              (min-width: 1440px) 1280px,
              (min-width: 640px) 960px,
              100vw
            " alt="${item.alt}" style="--image-aspect-ratio: ${aspect}; --image-object-fit: ${object_fit};" animate="true" slot="image">
          </hhds-image>      
        `;
      }

      if (item.categories) {
        let html = '<hhds-taggroup slot="tags">';

        for (let i = 0; i < 2; i++) {
          if (item.categories[i]) {
            html += `<hhds-tag>${item.categories[i].name}</hhds-tag>`;
          }
        }

        if (item.categories.length > 2) {
          html += `<hhds-tag>+${item.categories.length - 2}</hhds-tag>`;
        }

        html += `</hhds-taggroup>`;

        card.innerHTML += html;
      }

      if (item.external) {
        card.innerHTML += `<hhds-icon slot="icon" type="arrowupright"></hhds-icon>`;
      }

      this.btnLoadMore?.before(card);
    });
  }

  private removeLoadMoreButton() {
    if (this.btnLoadMore) {
      this.removeInfiniteScrollListener();
      this.btnLoadMore.remove();
    }
  }

  private renderAsCarousel() {
    this.isGrid = false;
    const slotElement = this.shadow.querySelector("slot");
    const assignedElements = slotElement?.assignedElements({ flatten: true });
    if (assignedElements) {
      const content = assignedElements.map((el) => el.outerHTML).join("") || "";
      this.shadow.innerHTML = `
        <hhds-carousel nav="false" type="custom" slidesperview="3">
          ${content}
        </hhds-carousel>
      `;
    }
  }

  private renderAsGrid() {
    this.isGrid = true;
    const classes = `grid--cards--${this.vars.get("type")} grid--cards--${this.vars.get("type")}--spacing`;
    let featuredHtml = ``;
    if (this.vars.get<boolean>(Attrs.showFeatured)) {
      featuredHtml = `
      <div class="hhds-cardgrid-featured container ">
        <div class="grid ${classes}">
          <slot name="featured"></slot>
        </div>
      </div>`;
    }

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
      <style>${css}</style>

      <div class="hhds-cardgrid-intro container">
        <slot name="intro"></slot>
      </div>
      ${featuredHtml}
      <div class="hhds-cardgrid-content container">
        <div class="grid ${classes}">
          <slot></slot>
        </div>
      </div>
    `;

    /* Set card classes */
    let mainSlot = this.shadow.querySelector(
      'slot:not([name="intro"]):not([name="featured"])'
    ) as HTMLSlotElement;
    let assignedElements = mainSlot?.assignedElements({ flatten: true });

    // hide .hhds-cardgrid-intro if it's empty
    let introSlot = this.shadow.querySelector('slot[name="intro"]') as HTMLSlotElement;
    let introElements = introSlot?.assignedElements({ flatten: true });
    if (introElements.length == 0) {
      let intro = this.shadow.querySelector(".hhds-cardgrid-intro") as HTMLElement;
      intro.style.display = "none";
    }

    assignedElements?.forEach((el) => {
      if (el.tagName.toLowerCase() === "hhds-card") {
        el.setAttribute("type", this.vars.get("type"));
        el.classList.add(...this.vars.get("grid").split(" "));
        this.numLoaded++;
      }
    });

    let featuredSlot = this.shadow.querySelector('slot[name="featured"]') as HTMLSlotElement;
    let assignedFeaturedElements = featuredSlot?.assignedElements({ flatten: true });
    assignedFeaturedElements?.forEach((el) => {
      if (el.tagName.toLowerCase() === "hhds-card") {
        el.setAttribute("type", this.vars.get("type"));
        el.classList.add("col-span-6", "md:col-span-6", "sm:col-span-4");
      }
    });

    window.addEventListener("resize", this.updateFinalRowAlignment.bind(this));
    this.updateFinalRowAlignment();

    if (this.vars.get("paged") && this.numLoaded == this.vars.get("paged")) {
      if (!this.vars.get("collapse") || window.matchMedia("(min-width: 640px)").matches) {
        // this.addLoadMoreButton();
      }
    }
  }

  updateFinalRowAlignment = () => {
    const itemsPerRow: number = 2;
    const isSmallBreakpoint = window.matchMedia("(min-width: 640px)").matches;
    const isMediumBreakpoint = window.matchMedia("(min-width: 960px)").matches;
    let mainSlot = this.shadow.querySelector(
      'slot:not([name="intro"]):not([name="featured"])'
    ) as HTMLSlotElement;
    let assignedElements = mainSlot?.assignedElements({ flatten: true });
    assignedElements.forEach((element) => ((element as HTMLElement)!.style.gridColumnStart = "unset"));
    // If not matching exclusively the small breakpoint, skip the final item re-alignment

    const visibleAssignedElements = assignedElements.filter(
      (el) => (el as HTMLElement).style.display !== "none"
    );

    if (!isSmallBreakpoint || isMediumBreakpoint) return;
    if (visibleAssignedElements.length % itemsPerRow !== 0) {
      const element = assignedElements[assignedElements.length - 1] as HTMLElement;
      element.style.gridColumnStart = "3";
    }
  };
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.type]: {
    description: "The type of grid",
    defaultValue: HHDSCardGridType.ImageHeadingTextAndButton,
    type: HHDSCardGridType,
  },
  [Attrs.grid]: {
    description: "The grid definition for the card grid.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cardAspect]: {
    description: "The aspect ratio of the card images.",
    defaultValue: HHDSCardAspectRatio.Intrinsic,
    type: HHDSCardAspectRatio,
  },
  [Attrs.paged]: {
    description:
      "Whether the grid supports load more. If set to 0, all load at once. If a number, this is how many to load at a time.",
    defaultValue: 0,
    type: Number,
  },
  [Attrs.collapse]: {
    description: "Collapse to a carousel on small screens.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.showFeatured]: {
    description: "Whether to show the featured section.",
    defaultValue: false,
    type: Boolean,
  },
};
