import css from "./buildercard.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSImageAttrNames, ArgSpecs as ImageArgSpecs } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSBuilderCard";
export const HHDSBuilderCardTagName: string = "hhds-builder-card";
const TAG_NAME: string = HHDSBuilderCardTagName;

export const HHDSBuilderCardAttrNames = {
  title: "title",
  body: "body",
  image: "image",
  link: "link",
  target: "target",
  logoImage: "logo-image",
};

const Attrs = HHDSBuilderCardAttrNames;

export class HHDSBuilderCard extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const title = this.vars.get<string>(Attrs.title);
    const body = this.vars.get<string>(Attrs.body);
    let image = this.vars.get<string>(Attrs.image);
    const logoImage = this.vars.get<string>(Attrs.logoImage);

    if (!image || image.length === 0) {
      image = "https://dummyimage.com/1600x900/000000/fff&text=No+Image";
    }

    let logoImageHtml = ``;
    if (logoImage?.length) {
      logoImageHtml = `<hhds-image class="${TAG_NAME}__footer--logo" src="${logoImage}"></hhds-image>`;
    }

    let ctaHtml = "";
    let target = this.vars.get<string>(Attrs.target);
    const link = this.vars.get<string>(Attrs.link);
    if (link?.length) {
      ctaHtml = `<hhds-button class="${TAG_NAME}__footer--cta" href=${link} target="${target}">Request More Info</hhds-button>`;
    }

    let footerHtml: string = "";
    if (logoImageHtml.length > 0 || ctaHtml.length > 0) {
      footerHtml = `
        <div class="${TAG_NAME}__footer">
          ${ctaHtml}
          ${logoImageHtml}
        </div>`;
    }

    this.shadow.innerHTML = /* html */ `
        <style>${globalStyles}</style>
        <style>${css}</style>
        <div class="${TAG_NAME}">
            <hhds-image
                src="${image}"
                ${this.markupResponsiveImages}
            >
            </hhds-image>
            <div class="${TAG_NAME}__under-image">
            <div>
                <hhds-richtext><h2 class="eyebrow eyebrow--01">${title}</h2></hhds-richtext>
                <hhds-richtext><p class="body body--02">${body}</p></hhds-richtext>
            </div>
            ${footerHtml}
            </div>
        </div>
		`;
  }

  get markupResponsiveImages() {
    const attrSrcSet = this.vars.get<string>(HHDSImageAttrNames.srcSet);
    const attrSizes = this.vars.get<string>(HHDSImageAttrNames.sizes);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `markupResponsiveImages`, {
        attrSrcSet,
        attrSizes,
      });

    if (attrSrcSet !== "" && attrSizes !== "") {
      return `srcset="${attrSrcSet}" sizes="${attrSizes}"`;
    } else {
      return "";
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.title]: {
    type: String,
    defaultValue: "",
    description: "The title of the card",
  },
  [Attrs.body]: {
    type: String,
    defaultValue: "",
    description: "The body text of the card",
  },
  [Attrs.image]: {
    type: String,
    defaultValue: "",
    description: "The image of the card",
  },
  [Attrs.link]: {
    type: String,
    defaultValue: "",
    description: "The link of the card",
  },
  [Attrs.target]: {
    type: String,
    defaultValue: "_blank",
    description: "The target of the link",
  },
  [Attrs.logoImage]: {
    type: String,
    defaultValue: "",
    description: "The logo image of the card",
  },
  [HHDSImageAttrNames.srcSet]: ImageArgSpecs[HHDSImageAttrNames.srcSet],
  [HHDSImageAttrNames.sizes]: ImageArgSpecs[HHDSImageAttrNames.sizes],
};
