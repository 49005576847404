import css from './banner.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSButton, HHDSButtonEvent } from "../Button/Button";


const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSBanner';
const TAG_NAME: string = 'hhds-banner';
export const HHDSBannerTagName: string = "hhds-banner";

// ////////////////////////////////////////////////////////////////////

export class HHDSBanner extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;

	private container!: HTMLElement;
	private main!: HTMLElement;
	private closeButton!: HHDSButton;

	private buttonClickFunc: any;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

		this.buttonClickFunc = (_event: Event) => {
			this.hide();
		};

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		this.parseAttributes();
		this.render();
		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);

		this.main = document.querySelector("main") as HHDSButton;

		this.closeButton = this.shadow.querySelector("hhds-button") as HHDSButton;
		this.closeButton?.addEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
	}

	hide(): void {
		this.container = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
		this.container.classList.toggle("hidden", true);

		this.main.setAttribute("style", "--main-top-margin: 30px; --main-top-margin-md: 30px; --main-top-margin-lg: 30px;");

		// Set a session-specific cookie
		document.cookie = "hhhcorporate_banner=dismissed=true; path=/;";

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        // Dispatch a custom event so that external elements can handle the banner being closed

        const customEvent = new CustomEvent('hidden');

        this.dispatchEvent(customEvent);
	}

	parseAttributes() {
		this.vars.parse(this, ArgSpecs);
	}

	render() {
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-banner">
				<div class="container">
					<div class="hhds-banner__content">
						<slot></slot>
						<hhds-button style="
							--button-primary-bg-color-hover: transparent;

							--button-primary-border-color-hover: var(--color-neutral-white);

							--button-primary-vertical-padding: 1px;
							--button-primary-vertical-padding-md: 5px;
							--button-primary-vertical-padding-lg: 5px;

							--button-primary-horizontal-padding: 3px;
							--button-primary-horizontal-padding-md: 7px;
							--button-primary-horizontal-padding-lg: 7px;

							--button-primary-min-height: none;
							--button-primary-min-height-md: none;
							--button-primary-min-height-lg: none;

							--button-primary-box-shadow: none;
							--button-primary-box-shadow-focus: 0px 0px 0px 3px rgba(255, 255, 255, 0.20);
						">
							<hhds-icon type="cross" slot="start" style="--icon-color: var(--color-neutral-white);"></hhds-icon>
						</hhds-button>
					</div>
				</div>
			</div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		this.closeButton?.removeEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}

}

export const ArgSpecs: ArgSpecDictionary = {};

