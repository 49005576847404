import css from "./subscribeform.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSInput } from "../Input/Input";
import { HHDSSelect } from "../Select/Select";
import { HHDSCheckboxButton } from "../CheckboxButton/CheckboxButton";
import { EMPTY_STRING } from "../../Constants";
import { HHDSCheckboxButtonEvents } from "../CheckboxButton/CheckboxButtonEvents";

const DEBUG_VERBOSE = false;
const CLASS_NAME: string = "HHDSSubscribeForm";
export const HHDSSubscribeFormTagName: string = "hhds-subscribe-form";
const TAG_NAME: string = HHDSSubscribeFormTagName;

export const HHDSSubscribeFormAttrNames = {
  action: "action",
  return_url: "return_url",
  hasImage: "has-image",
};

const Attrs = HHDSSubscribeFormAttrNames;

export class HHDSSubscribeForm extends Component {
  private formEl!: HTMLFormElement;

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const attrHasImage = this.vars.get<boolean>(Attrs.hasImage);

    const formClasses = attrHasImage
      ? "col-span-6 sm:col-span-8 md:col-span-8 lg:col-span-6"
      : "col-span-6 sm:col-span-8 md:col-start-3 md:cols-span-8 lg:col-start-4 lg:col-span-6";

    const image = attrHasImage ? '<slot name="image"></slot>' : EMPTY_STRING;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Check for a search param to see if we need to pre-select an option

    const url = new URL(window.location.href);

    const { searchParams } = url;

    const queryParamBuilder = searchParams.get("builder");

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
    this.shadow.innerHTML = /* html */ `
<style>${globalStyles}</style>
<style>${css}</style>
<div class="${TAG_NAME}">
    <div class="container">
        <div class="grid">
            ${image}
            <form
                class="${TAG_NAME}__form ${formClasses}"
                method="post"
                action="${this.vars.get(Attrs.action)}"
            >
                <hhds-richtext ref="intro">
                    <slot></slot>
                </hhds-richtext>

                <hhds-input
                    ref="name-first"
                    required="true"
                    error-message="Required"
                    name="name-first"
                    label="First Name"
                    type="text"
                    state="default"
                    style="--input-width: 100%;"></hhds-input>

                <hhds-input
                    ref="name-last"
                    required="true"
                    error-message="Required"
                    name="name-last"
                    label="Last Name"
                    type="text"
                    state="default"
                    style="--input-width: 100%;"></hhds-input>

                <hhds-input
                    ref="email"
                    required="true"
                    error-message="Required"
                    name="email"
                    label="Email Address"
                    type="text"
                    state="default"
                    style="--input-width: 100%;"></hhds-input>

                <hhds-input
                    ref="phone"
                    error-message="Required"
                    name="phone"
                    label="Phone No."
                    type="email"
                    state="default"
                    show-optional="true"
                    style="--input-width: 100%;"></hhds-input>

                <hhds-select
                    ref="live"
                    name="where-live"
                    required="true"
                    error-message="Required"
                    options="[{&quot;name&quot;: &quot;Please select...&quot;, &quot;value&quot;: null},{&quot;name&quot;: &quot;Option One&quot;, &quot;value&quot;: &quot;ValueOne&quot;},{&quot;name&quot;: &quot;Option Two&quot;, &quot;value&quot;: &quot;ValueTwo&quot;},{&quot;name&quot;: &quot;Option Three&quot;, &quot;value&quot;: &quot;ValueThree&quot;}]"
                    label="Where do you currently live?"
                    state="default"
                    style="--select-width: 100%;"></hhds-select>

                <hhds-select
                    ref="builder"
                    name="which-builder"
                    ${queryParamBuilder && `option-selected=${queryParamBuilder}`}
                    show-optional="true"
                    options="[{&quot;name&quot;: &quot;Please select...&quot;, &quot;value&quot;: null},{&quot;name&quot;: &quot;Option One&quot;, &quot;value&quot;: &quot;ValueOne&quot;},{&quot;name&quot;: &quot;Option Two&quot;, &quot;value&quot;: &quot;ValueTwo&quot;},{&quot;name&quot;: &quot;Option Three&quot;, &quot;value&quot;: &quot;ValueThree&quot;}]"
                    label="Which builder are you most interested in?"
                    state="default"
                    style="--select-width: 100%;"></hhds-select>

                <hhds-checkboxbutton
                    ref="professional"
                    name="is-real-estate-professional"
                    value="yes"
                    style="--checkbox-button-width: 100%;">I am a real estate professional</hhds-checkboxbutton>

                <div ref="terms" style="display: flex; align-items: center;">
                    <hhds-checkboxbutton
                        ref="terms"
                        name="terms"
                        required="true"
                        error-message="Required"
                        value="agreed-terms"
                        style="--checkbox-button-width: 100%;"></hhds-checkboxbutton>
                    <slot name="terms"></slot>
                </div>

                <input type="hidden" name="return_url" value="${this.vars.get(Attrs.return_url)}" />

                <div ref="btn-submit">
                    <hhds-button type="primary" mode="light" role="submit">Submit</hhds-button>
                    <p ref="terms-error-message" class="bodytext bodytext--03" style="color: var(--color-secondary-red-500);">You must accept the terms of service before submitting.</p>
                </div>
            </form>
        </div>
    </div>
</div>
`;

    this.formEl = this.shadowRoot?.querySelector("form") as HTMLFormElement;

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "init", `this.formEl`, this.formEl);

    if (!this.formEl)
      throw new Error(`${CLASS_NAME} - Unable to derive form in component!`);

    this.addListeners();

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);
  }

  // ////////////////////////////////////////////////////////////////////

  private addListeners() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.addListeners.name);

    const submitBtn = this.shadow.querySelector(`[ref="btn-submit"]`);
    submitBtn?.addEventListener("click", this.handleOnSubmit.bind(this));

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // We need to hide the terms error message if shown once user accepts terms

    const selectorTerms = `hhds-checkboxbutton[name="terms"]`;

    const checkboxTerms = this.shadow.querySelector(
      selectorTerms
    ) as HHDSCheckboxButton;

    if (!checkboxTerms)
      throw new Error(`Unable to derive selector: ${selectorTerms}`);

    checkboxTerms.addEventListener(
      HHDSCheckboxButtonEvents.Changed,
      (event) => {
        const { detail } = event as CustomEvent;

        if (detail.checked) {
          this.showTermsError(false);
        }
      }
    );
  }

  // ////////////////////////////////////////////////////////////////////

  handleOnSubmit(event: Event) {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.handleOnSubmit.name, { event });

    event.preventDefault();

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.validate();
  }

  // ////////////////////////////////////////////////////////////////////

  private validate() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.validate.name);

    if (!this.shadowRoot)
      throw new Error(`${CLASS_NAME} - Unable to derive shadowRoot!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Debug log data

    const formData = new FormData(this.formEl);

    const formDataObject = Object.fromEntries(formData.entries());
    DEBUG_VERBOSE && console.table(formDataObject);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Test each input or group
    // INPUT

    const validationStates: any[] = [];

    const inputsEls = Array.from(
      this.shadowRoot.querySelectorAll("hhds-input")
    ) as HHDSInput[];

    inputsEls.forEach((inputEl) => {
      const name = inputEl.getAttribute("name");

      validationStates.push({ name, state: inputEl.isValid() });
    });

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.validate.name, {
        inputsEls,
        validationStates,
      });

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // SELECT

    const selectEls = Array.from(
      this.shadowRoot.querySelectorAll("hhds-select")
    ) as HHDSSelect[];

    selectEls.forEach((selectEl) => {
      const name = selectEl.getAttribute("name");
      validationStates.push({ name, state: selectEl.isValid() });
    });

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // CHECKBOX
    // Only need to test the terms

    const selectorTerms = `hhds-checkboxbutton[name="terms"]`;

    const checkboxTerms = this.shadowRoot.querySelector(
      selectorTerms
    ) as HHDSCheckboxButton;

    if (!checkboxTerms)
      throw new Error(`Unable to derive selector: ${selectorTerms}`);

    const checkBoxValid = checkboxTerms.isValid() as boolean;

    const name = checkboxTerms.getAttribute("name");

    validationStates.push({ name, state: checkBoxValid });

    if (!checkBoxValid) {
      this.showTermsError();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const formIsValid = validationStates.every((value) => value.state === true);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.addListeners.name, {
        validationStates,
        formIsValid,
      });

    if (formIsValid) {
      this.submit();
    }
  }

  private showTermsError(state: boolean = true) {
    // Show error
    const selector = `[ref="terms-error-message"]`;

    const errorEl = this.shadow.querySelector(selector) as HTMLElement;

    if (!errorEl) throw new Error(`Unable to derive selector: ${selector}`);

    errorEl.style.display = state ? "block" : "none";
  }

  private submit() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.submit.name);

    const action = this.vars.get<string>(Attrs.action);

    if (!action) throw new Error("No action defined!");

    this.formEl.submit();
  }

  // ////////////////////////////////////////////////////////////////////

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.action]: {
    description: "URL to submit the form data to.",
    defaultValue: "",
    typeString: "action",
    type: String,
  },
  [Attrs.return_url]: {
    description: "URL to return to after form submission.",
    defaultValue: "",
    typeString: "return_url",
    type: String,
  },
  [Attrs.hasImage]: {
    description: "Whether the layout contains an image",
    defaultValue: false,
    type: Boolean,
  },
};
