import css from "./connectorline.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSConnectorLine";
export const HHDSConnectorLineTagName: string = "hhds-connector-line";
const TAG_NAME: string = HHDSConnectorLineTagName;

export const HHDSConnectorLineAttrNames = {
  target: "target",
};

const Attrs = HHDSConnectorLineAttrNames;

export class HHDSConnectorLine extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}"/>
		`;

    const component = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    if (!component) return;

    // A JS solution for updating the position may be necessary if it's not possible to position
    // the ConnectorLine component cleanly at the bottom of each block using the block PHP.
    // This would require the ability to identify the target element for each ConnectorLine, as
    // it feels too risky to rely on an assumption of previous or next sibling.
    const target = this.getAttribute(Attrs.target);
    const targetElement = document.querySelector(`[${Attrs.target}="${target}"]`);
    if (targetElement) {
      component.classList.add(`${TAG_NAME}--fixed`);
      const updatePosition = (): void => {
        const targetBounds = targetElement.getBoundingClientRect();
        const targetBottom = targetBounds.top + targetBounds.height;
        component.style.setProperty("--connector-line-y", `${targetBottom}px`);
      };
      window.addEventListener("resize", () => updatePosition());
      window.addEventListener("scroll", () => updatePosition());
      updatePosition();
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.target]: {
    type: String,
    description: "The ID of the target element",
    defaultValue: "",
  },
};
