import css from "./select.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

import { HHDSSelectState } from "./SelectState";
import { HHDSInputState } from "../Input/InputState";
import { EMPTY_STRING } from "../../Constants";

const DEBUG_VERBOSE = false;
const CLASS_NAME: string = "HHDSSelect";
export const HHDSSelectTagName: string = "hhds-select";

export const HHDSSelectAttrNames = {
  required: "required",
  id: "id",
  name: "name",
  placeholder: "placeholder",
  options: "options",
  label: "label",
  state: "state",
  optionSelected: "option-selected",
  showSpacer: "show-spacer",
  showOptional: "show-optional",
};

const Attrs = HHDSSelectAttrNames;

export class HHDSSelect extends Component {
  private inputElement!: HTMLSelectElement;
  public value: string = "";

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const required = this.vars.get<boolean>(Attrs.required);
    const id = this.vars.get<string>(Attrs.id);
    const label = this.vars.get<string>(Attrs.label);
    const placeholder = this.vars.get<string>(Attrs.placeholder);
    const state = this.vars.get<HHDSSelectState>(Attrs.state);
    const options = JSON.parse(this.vars.get<Object>(Attrs.options));
    const optionSelected = this.vars.get<string>(Attrs.optionSelected);

    // If not an existing value then overwrite it
    if (this.value === "") if (optionSelected) this.value = optionSelected;

    let optionsHTML = "";
    options.forEach((item: any) => {
      const { name, value } = item;
      const selected = this.value === value;
      // Ensure we preserve the chosen value on re-render
      optionsHTML += `<option value="${value}" ${selected ? "selected" : EMPTY_STRING}>${name}</option>`;
    });

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let error = ``;
    if (required && state === HHDSSelectState.Error) {
      error = `<span class="${HHDSSelectTagName}__required label">Required</span>`;
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let optional = ``;
    if (this.vars.get<boolean>(Attrs.showOptional)) {
      optional = `<span class="${HHDSSelectTagName}__optional label">Optional</span>`;
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const showSpacer = this.vars.get<boolean>(Attrs.showSpacer);
    const labelOrErrorExists =
      label.length > 0 || (state === HHDSSelectState.Error && required);
    const showUpper = labelOrErrorExists || showSpacer;
    let extraUpperClasses = "";

    if (showUpper && label.length == 0) {
      extraUpperClasses += " hhds-select__upper--nolabel";
    }
    if (!labelOrErrorExists && showSpacer) {
      extraUpperClasses += " hhds-select__upper--spacer";
    }

    this.shadow.innerHTML = /* html */ `
		<style>${globalStyles}</style>
		<style>${css}</style>
		<div class="hhds-select ${state == "error" ? "hhds-select--error" : ""}">
      <div class="hhds-select__upper ${extraUpperClasses}" style="display: ${showUpper ? "flex" : "none"};">
			  ${label.length > 0 ? `<label class="hhds-select__label label" ${id ? ` for="${id}" ` : ""}">${label}</label>` : ""}
			  ${optional ? optional : error}
      </div>
      <div class="hhds-select__lower">
        <div class="hhds-select__separator"></div>
        <select class="hhds-select__select ${placeholder.length > 0 ? "hhds-select__select--placeholder" : ""} ${label.length > 0 ? "hhds-select__select--label" : ""} form-text" ${required ? "required" : ""} ${id ? `id="${id}" name="${id}"` : ""} aria-label="Select">
        ${placeholder.length > 0 ? `<option class="hhds-select__option" value="" disabled selected>${placeholder}</option>` : ``}
        ${optionsHTML}
        </select>
      </div>
		</div>`;

    this.inputElement = this.shadow.querySelector(
      "select"
    ) as HTMLSelectElement;
    if (!this.inputElement)
      throw new Error(`${CLASS_NAME} - Unable to derive input element!`);

    this.addListeners();

    this.attachFormDataListener();
  }

  attachFormDataListener() {
    const form = this.closest("form");
    if (!form) return;

    form.addEventListener("formdata", (event) => {
      const name = this.vars.get<string>(Attrs.name);

      DEBUG_VERBOSE &&
        console.log(
          CLASS_NAME,
          "Form data event. Appending: ",
          name,
          this.inputElement ? this.inputElement.value : ""
        );

      const { formData } = event;

      // Ensure not to duplicate data
      if (formData.has(name)) formData.delete(name);

      formData.append(name, this.inputElement ? this.inputElement.value : "");
    });
  }

  private addListeners(): void {
    this.inputElement.addEventListener("change", () => {
      this.value = this.inputElement.value;
      this.emitEvent("selectChanged", this.value);
    });
  }

  public isValid() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.isValid.name);
    DEBUG_VERBOSE && console.dir(this.inputElement);

    const attrRequired = this.vars.get<boolean>(Attrs.required);

    let isValidState;

    if (attrRequired === false) {
      isValidState = true;
    } else {
      isValidState = this.isTypeValid();
    }

    const nextAttrStateValue = isValidState
      ? HHDSInputState.Default
      : HHDSInputState.Error;

    this.setAttribute(Attrs.state, nextAttrStateValue);

    return isValidState;
  }

  isTypeValid() {
    const { value } = this.inputElement;

    DEBUG_VERBOSE && console.log(CLASS_NAME, this.isTypeValid.name, { value });

    return value !== "null";
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.required]: {
    description: "The requried attribute that is assigned to the input",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.id]: {
    description:
      "Assigned to the following attributes: select's name, select's id and label's for",
    defaultValue: "",
    type: String,
  },
  [Attrs.placeholder]: {
    description: "The default placeholder that is assigned to the select",
    defaultValue: "",
    type: String,
  },
  [Attrs.options]: {
    description: "String encoded JSON array of name/value pairs",
    defaultValue: "",
    type: String,
  },
  [Attrs.name]: {
    description: "The name that is assigned to the select",
    defaultValue: "",
    type: String,
  },
  [Attrs.optionSelected]: {
    description: "The value of the initial selected option",
    defaultValue: "",
    type: String,
  },
  [Attrs.label]: {
    description: "The label that is assigned to the select",
    defaultValue: "",
    type: String,
  },
  [Attrs.state]: {
    description: "The select state",
    defaultValue: HHDSSelectState.Default,
    type: HHDSSelectState,
  },
  [Attrs.showSpacer]: {
    description: "Whether to pad the top even if there is no label or error",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.showOptional]: {
    description:
      "Whether to show the 'optional' text, can interfer with error messages.",
    defaultValue: false,
    type: Boolean,
  },
};
