import css from "./videodirect.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoEvent } from "../../utils/VideoTypes";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = true;
const CLASS_NAME: string = "[HHDSVideoDirect]";
export const HHDSVideoDirectTagName: string = "hhds-video-direct";
const TAG_NAME: string = HHDSVideoDirectTagName;

export const HHDSVideoDirectAttrNames = {
  src: "src",
  cover: "cover",
  controls: "controls",
  autoplay: "autoplay",
  loop: "loop",
  aspect: "aspect",
  loopPoint: "loop-point",
  startPoint: "start-point",
};

const Attrs = HHDSVideoDirectAttrNames;

export class HHDSVideoDirect extends Component {
  private listenerFuncs: { [key: string]: EventListener } = {};
  private videoElement: HTMLVideoElement | null = null;
  private loopPoint: number = 0;
  private startPoint: number = 0;
  private started: boolean = false;
  private coverElement: HTMLElement | null = null;

  constructor() {
    super();
    this.createEventCallbacks();
  }

  private createEventCallbacks(): void {
    this.listenerFuncs["click"] = () => this.play();
    this.listenerFuncs["loadeddata"] = () => this.onVideoLoaded();
    this.listenerFuncs["timeupdate"] = (event: Event) => this.onVideoTimeUpdate(event);
    this.listenerFuncs["play"] = () => this.onVideoPlay();
    this.listenerFuncs["ended"] = () => this.onVideoEnded();
    this.listenerFuncs["pause"] = () => this.onVideoPaused();
  }

  protected override init(): void {
    const src = this.vars.get<string>(Attrs.src);
    if (!src) return;

    this.loopPoint = this.vars.get<number>(Attrs.loopPoint);
    this.startPoint = this.vars.get<number>(Attrs.startPoint);

    const aspect = this.vars.get<string>(Attrs.aspect);

    let buttonHtml: string;
    if (this.vars.get<boolean>(Attrs.autoplay)) {
      buttonHtml = "";
    } else {
      //buttonHtml = `<button class="${TAG_NAME}__button">PLAY</button>`;
      let styles: string[] = [];
      styles.push("--button-min-width: 64px;");
      styles.push("--button-overlay-min-height: 60px;");
      styles.push("--button-overlay-min-height-md: 60px;");
      styles.push("--button-overlay-min-height-lg: 60px;");
      styles.push("--button-overlay-min-height-xl: 60px;");
      styles.push("--button-overlay-radius: 50%;");
      let iconHtml = `<hhds-icon type="play" slot="start" style="--icon-color: var(--color-neutral-white);"></hhds-icon>`;
      buttonHtml = `<hhds-button type="overlay" style="${styles.join(" ")}">${iconHtml}</hhds-button>`;
    }

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
      <style>${css}</style>
      <div class="${TAG_NAME}" style="--video-direct-aspect-ratio: ${aspect};">
        <video playsinline></video>
        <div class="${TAG_NAME}__cover">
          ${buttonHtml}
        </div>
      </div>
    `;

    this.coverElement = this.shadow.querySelector(`.${TAG_NAME}__cover`) as HTMLElement;
    const coverImageUrlString = this.vars.get<string>(Attrs.cover);
    if (coverImageUrlString) {
      this.coverElement.style.backgroundImage = `url('${coverImageUrlString}')`;
    } else {
      this.coverElement.remove();
    }

    this.videoElement = this.shadow.querySelector("video") as HTMLVideoElement;
    if (this.videoElement) {
      this.videoElement.src = src;
      this.videoElement.loop = this.vars.get<boolean>(Attrs.loop);
      this.videoElement.autoplay = this.vars.get<boolean>(Attrs.autoplay);
      this.videoElement.playsInline = true;
      this.videoElement.controls = this.vars.get<boolean>(Attrs.controls);
      this.videoElement.muted = this.vars.get<boolean>(Attrs.autoplay);
      this.videoElement.style.display = "none";

      this.videoElement.addEventListener("loadedmetadata", () => {
        const videoEl = this.shadow.querySelector("video") as HTMLVideoElement;
        const intrinsicWidth = videoEl.videoWidth;
        const intrinsicHeight = videoEl.videoHeight;
        if (videoEl) {
          videoEl.style.display = "block";
          console.log(CLASS_NAME, `Intrinsic size: ${intrinsicWidth}x${intrinsicHeight}`);
          videoEl.style.width = "100%";
          videoEl.style.minWidth = `${(intrinsicWidth / intrinsicHeight) * 100}%`;
          videoEl.style.height = `${(intrinsicHeight / intrinsicWidth) * 100}%`;
          videoEl.style.minHeight = "100%";

          // If aspect is not set, update it based on the video's aspect ratio
          const component = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
          if (component) {
            const calculatedAspect = `${intrinsicWidth}/${intrinsicHeight}`;
            console.log("CALCULATED ASPECT: ", calculatedAspect);
            component.style.setProperty("--video-direct-aspect-ratio", calculatedAspect);
          }
        }
      });

      this.addListeners();
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.removeListeners();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  addListeners(): void {
    const button = this.shadow.querySelector(`.${TAG_NAME}__button`) as HTMLElement;
    button?.addEventListener("click", this.listenerFuncs["click"]);
    if (this.videoElement) {
      this.videoElement.addEventListener("loadeddata", this.listenerFuncs["loadeddata"]);
      this.videoElement.addEventListener("timeupdate", this.listenerFuncs["timeupdate"]);
      this.videoElement.addEventListener("play", this.listenerFuncs["play"]);
      this.videoElement.addEventListener("ended", this.listenerFuncs["ended"]);
      this.videoElement.addEventListener("pause", this.listenerFuncs["pause"]);
    }
  }

  removeListeners(): void {
    const button = this.shadow.querySelector(`.${TAG_NAME}__button`) as HTMLElement;
    button?.removeEventListener("click", this.listenerFuncs["click"]);
    if (this.videoElement) {
      this.videoElement.removeEventListener("loadeddata", this.listenerFuncs["loadeddata"]);
      this.videoElement.removeEventListener("timeupdate", this.listenerFuncs["timeupdate"]);
      this.videoElement.removeEventListener("play", this.listenerFuncs["play"]);
      this.videoElement.removeEventListener("ended", this.listenerFuncs["ended"]);
      this.videoElement.removeEventListener("pause", this.listenerFuncs["pause"]);
    }
  }

  isPlaying(): Promise<boolean> {
    if (!this.videoElement) return Promise.resolve(false);
    return Promise.resolve(!this.videoElement.paused);
  }

  play() {
    if (this.videoElement) {
      this.videoElement.play();
    }
  }

  pause() {
    if (this.videoElement) {
      this.videoElement.pause();
    }
  }

  onVideoLoaded() {
    DEBUG_VERBOSE && console.log("Video loaded");
    this.emitEvent(HHDSVideoEvent.loaded);
    if (this.vars.get<boolean>(Attrs.autoplay)) {
      this.videoElement?.play();
    }
  }

  onVideoTimeUpdate(_event: Event) {
    if (!this.started) {
      this.started = true;
    }
    if (this.videoElement && this.loopPoint > 0 && this.videoElement.currentTime >= this.loopPoint) {
      this.loopPlayback();
    }
    this.emitEvent(HHDSVideoEvent.timeUpdate);
  }

  onVideoPlay() {
    DEBUG_VERBOSE && console.log("Video playing");
    this.toggleCover(false);
    this.emitEvent(HHDSVideoEvent.play);
  }

  onVideoEnded() {
    DEBUG_VERBOSE && console.log("Video ended");
    if (this.videoElement && !this.videoElement.loop) {
      this.loopPlayback();
    } else {
      this.emitEvent(HHDSVideoEvent.ended);
      this.toggleCover(true);
    }
  }

  onVideoPaused() {
    DEBUG_VERBOSE && console.log("Video paused");
    this.emitEvent(HHDSVideoEvent.pause);
  }

  loopPlayback() {
    DEBUG_VERBOSE && console.log(`Should loop at ${this.loopPoint} to ${this.startPoint}`);
    if (this.videoElement) {
      this.videoElement.currentTime = this.startPoint;
      this.videoElement?.play();
    }
  }

  toggleCover(visible: boolean) {
    if (this.coverElement) {
      this.coverElement.style.display = visible ? "flex" : "none";
    }
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "The video URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cover]: {
    description: "The URL of the cover image.",
    defaultValue: "/video-cover.jpg",
    type: String,
  },
  [Attrs.controls]: {
    description: "Whether to show the video controls.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.autoplay]: {
    description: "Whether to autoplay the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.loop]: {
    description: "Whether to loop the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.aspect]: {
    description: "The aspect ratio of the video.",
    defaultValue: "16/9",
    type: String,
  },
  [Attrs.loopPoint]: {
    description: "A custom time (instead of the end of the video) at which to loop the video.",
    defaultValue: 0,
    type: Number,
  },
  [Attrs.startPoint]: {
    description: "The time to which the video should be rewinded after looping.",
    defaultValue: 0,
    type: Number,
  },
};
