import css from "./articlecontent.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSArticleContent";
export const HHDSArticleContentTagName: string = "hhds-articlecontent";
const TAG_NAME: string = HHDSArticleContentTagName;

export const HHDSArticleContentAttrNames = {};

//const Attrs = HHDSArticleContentAttrNames;

export class HHDSArticleContent extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}"><slot></slot></div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {};
