import css from './domino.scss?inline';
import globalStyles from '../../index.scss?inline'; 
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSDomino';
export const HHDSDominoTagName: string = "hhds-domino";
const TAG_NAME: string = HHDSDominoTagName;

export const HHDSDominoAttrNames = {};

// const Attrs = HHDSDominoAttrNames;

export class HHDSDomino extends Component {

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

		this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
        <div class="container">
          <div class="grid variant-large">
            <div class="col-span-6 sm:col-span-4 md:col-span-6 lg:col-span-5 lg:col-start-2">                          
              <slot name="lg-start"></slot>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-6 lg:col-span-5 lg:col-start-7">
              <slot name="lg-end"></slot>
            </div>
          </div>
          <div class="grid variant-small">
            <div class="col-span-6 sm:col-span-4 md:col-span-6">                          
              <slot name="sm-header"></slot>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-6">
              <slot name="sm-image"></slot>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-6">
              <slot name="sm-body"></slot>
            </div>
          </div>
        </div>
      </div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {};

