export enum HHDSCardGridSource {    
    People = 'people',
    Office = 'office',
    Location = 'location',  
    Community = 'community',
    Home = 'home',
    Press = 'press',
    Insight = 'insight',
    Default = 'custom'
}

export enum HHDSCardGridType {
    ImageHeadingAndText = 'image-heading-text', 
    ImageHeadingTextAndButton = 'image-heading-text-button',
    HeadingTextAndButton = 'heading-text-button',   
    Article = 'article',
    External = 'external',
    Builders = 'builders'
}

export enum HHDSCardGridColumns {    
    Three = 3,
    Four = 4
}

export enum HHDSCardAspectRatio {
    Intrinsic = "unset",
    Square = "1/1",
    ThreeByTwo = "3/2", 
    FourByThree = "4/3",
    SixteenByTen = "16/10",
    SixteenByNine = "16/9"
}